import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ISlice {
  disciplines: string[];
}

const sliceInitialState: ISlice = {
  disciplines: [],
};

const customSlice = createSlice({
  name: "disciplines",
  initialState: sliceInitialState,
  reducers: {
    insertDisciplines: (state, action: PayloadAction<string[]>) => ({
      ...state,
      disciplines: action.payload,
    }),
    resetDisciplines: (state) => ({ ...state, disciplines: [] }),
  },
});
export const actions = customSlice.actions;
export default customSlice.reducer;
