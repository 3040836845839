import React from "react";
import { Box, Collapse, Link } from "@chakra-ui/react";

import SelectFileFromOptions from "./SelectFile";
import UploadFile from "./UploadFile";

type AttachmentType = "newUpload" | "selection" | "innerLink";

interface IAttachedFile {
  id?: string;
  name: string;
  file?: File;
}

export interface IAllowedAttachment {
  label: string;
  type: AttachmentType;
  options?: IAttachedFile[];
}
interface IProps {
  attachedFiles: IAttachedFile[];
  setAttachedFiles: (attachedFiles: IAttachedFile[]) => void;
  allowedAttachments: IAllowedAttachment[];
}

const Index: React.FC<IProps> = (props) => {
  const { attachedFiles, setAttachedFiles, allowedAttachments } = props;

  const [isSearchOpen, setIsSearchOpen] = React.useState(false);

  const insertSelectedFile = (file: IAttachedFile | IAttachedFile[]) => {
    if (
      !Array.isArray(file) &&
      attachedFiles.findIndex((f) => f.id === file.id) === -1
    )
      return setAttachedFiles([...attachedFiles, file]);
    else if (Array.isArray(file))
      setAttachedFiles([
        ...attachedFiles,
        ...file.filter(
          (f) => !attachedFiles.map((aF) => aF.name).includes(f.name)
        ),
      ]);
  };

  return (
    <Box>
      {allowedAttachments
        .filter((x) => x.type === "selection" || x.type === "innerLink")
        .map((allowedAttachment, index) => (
          <React.Fragment key={`attachfile_${index}`}>
            <Link
              onClick={(_) => setIsSearchOpen(!isSearchOpen)}
              fontSize="small"
              display="block"
            >
              {allowedAttachment.label}
            </Link>
            <Collapse in={isSearchOpen} unmountOnExit animateOpacity>
              <SelectFileFromOptions
                allowedAttachment={allowedAttachment}
                selectedFiles={attachedFiles}
                insertSelectedFile={insertSelectedFile}
              />
            </Collapse>
          </React.Fragment>
        ))}
      {allowedAttachments
        .filter((x) => x.type === "newUpload")
        .map((allowedAttachment, index) => (
          <UploadFile
            key={`uploadFile_${index}`}
            insertSelectedFile={insertSelectedFile}
            allowedAttachment={allowedAttachment}
          />
        ))}
    </Box>
  );
};

export default Index;
