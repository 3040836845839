import React from "react";
import { sub, isAfter, isBefore } from "date-fns";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { actions } from "../../redux/SubmittalSlice";
import { IFilter } from "../../shared/FiltersSideBar/FilterGroup";

import FilterPane from "../../shared/FiltersSideBar/FilterPane";

interface IProps {}

const Filters: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();
  const disciplines = useAppSelector((state) => state.disciplines.disciplines);
  const submittalList = useAppSelector(
    (state) => state.submittalStorage.submittalList
  );

  const [disciplinesFilter, setDisciplinesFilter] = React.useState<IFilter[]>(
    []
  );
  const [statusFilter, setStatusFilter] = React.useState<IFilter[]>([]);
  const [criticalityFilter, setCriticalityFilter] = React.useState<IFilter[]>(
    []
  );
  const [dateFilter, setDateFilter] = React.useState<IFilter[]>([]);

  const applyFilters = () => {
    let modifiedList = [...submittalList];

    // eslint-disable-next-line array-callback-return
    modifiedList = modifiedList.filter((item) => {
      if (
        disciplinesFilter.find((filter) => filter.key === item.discipline)
          ?.isActive
      )
        return item;
    });
    // eslint-disable-next-line array-callback-return
    modifiedList = modifiedList.filter((item) => {
      if (
        !item.status &&
        statusFilter.find((filter) => filter.key === "noStatus")?.isActive
      )
        return item;
      if (statusFilter.find((filter) => filter.key === item.status)?.isActive)
        return item;
    });
    // eslint-disable-next-line array-callback-return
    modifiedList = modifiedList.filter((item) => {
      if (
        criticalityFilter.find((filter) => filter.key === item.critical)
          ?.isActive
      )
        return item;
    });

    // eslint-disable-next-line array-callback-return
    modifiedList = modifiedList.filter((item) => {
      if (
        dateFilter.find((filter) => filter.key === "NEW")?.isActive &&
        isAfter(new Date(item.date!), sub(new Date(), { weeks: 2 }))
      )
        return item;
      if (
        dateFilter.find((filter) => filter.key === "OLD")?.isActive &&
        isBefore(new Date(item.date!), sub(new Date(), { weeks: 2 })) &&
        isAfter(new Date(item.date!), sub(new Date(), { weeks: 8 }))
      )
        return item;
      if (
        dateFilter.find((filter) => filter.key === "VOLD")?.isActive &&
        isBefore(new Date(item.date!), sub(new Date(), { weeks: 8 }))
      )
        return item;
    });
    dispatch(actions.insertSelectedList(modifiedList));
  };

  React.useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    submittalList,
    disciplinesFilter,
    statusFilter,
    criticalityFilter,
    dateFilter,
  ]);

  React.useEffect(() => {
    if (!!disciplines.length) {
      setDisciplinesFilter(
        disciplines.map((discipline) => ({
          label: discipline,
          key: discipline,
          quantity: submittalList.filter(
            (item) => item.discipline === discipline
          ).length,
          isActive: true,
        }))
      );
      setStatusFilter([
        {
          label: "Submittals to approve",
          quantity: submittalList.filter((item) => !item.status).length,
          key: "noStatus",
          isActive: true,
          color: "white.500",
        },
        {
          label: "A - Approved",
          quantity: submittalList.filter((item) => item.status === "A").length,
          key: "A",
          isActive: true,
        },
        {
          label: "B - Approved with comments",
          quantity: submittalList.filter((item) => item.status === "B").length,
          isActive: true,
          key: "B",
        },
        {
          label: "C - Rejected",
          quantity: submittalList.filter((item) => item.status === "C").length,
          isActive: true,
          key: "C",
        },
      ]);
      setCriticalityFilter([
        {
          label: "Critical",
          quantity: submittalList.filter((item) => item.critical).length,
          key: true,
          isActive: true,
          color: "primary.main",
        },
        {
          label: "Not critical",
          quantity: submittalList.filter((item) => !item.critical).length,
          key: false,
          isActive: true,
        },
      ]);
      setDateFilter([
        {
          label: "New ( < 2 weeks)",
          quantity: submittalList.filter((item) =>
            isAfter(new Date(item.date!), sub(new Date(), { weeks: 2 }))
          ).length,
          key: "NEW",
          isActive: true,
        },
        {
          label: "Old ( 2-8 weeks)",
          quantity: submittalList.filter(
            (item) =>
              isBefore(new Date(item.date!), sub(new Date(), { weeks: 2 })) &&
              isAfter(new Date(item.date!), sub(new Date(), { weeks: 8 }))
          ).length,
          key: "OLD",
          isActive: true,
        },
        {
          label: "Very old ( > 8 weeks)",
          quantity: submittalList.filter((item) =>
            isBefore(new Date(item.date!), sub(new Date(), { weeks: 8 }))
          ).length,
          key: "VOLD",
          isActive: true,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disciplines, submittalList]);

  return (
    <FilterPane
      filterGroups={[
        {
          filterKey: "discipline",
          filterOptions: disciplinesFilter,
          filterSetter: setDisciplinesFilter,
          title: "Disciplines",
        },
        {
          filterKey: "status",
          filterOptions: statusFilter,
          filterSetter: setStatusFilter,
          title: "Status",
        },
        {
          filterKey: "criticality",
          filterOptions: criticalityFilter,
          filterSetter: setCriticalityFilter,
          title: "Criticality",
        },
        {
          filterKey: "registration",
          filterOptions: dateFilter,
          filterSetter: setDateFilter,
          title: "Registration",
        },
      ]}
    />
  );
};

export default Filters;
