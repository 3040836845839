import React from "react";
import { Button, Input } from "@chakra-ui/react";

interface IProps {
  formikFormProp: any;
  placeholder?: string;
}

const UploadFileInput: React.FC<IProps> = (props) => {
  const { formikFormProp, placeholder } = props;
  const [file, setFile] = React.useState<File | null>(null);

  const inputRef: any = React.useRef();

  const onButtonClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      <Button variant="solidPrimary" w="100%" onClick={onButtonClick}>
        {file?.name || placeholder || "Select file from your computer"}
      </Button>
      <Input
        ref={inputRef}
        type="file"
        onChange={(event) => {
          if (event.target.files) {
            setFile(event.target.files[0]);
            formikFormProp.setFieldValue("file", event.target.files[0]);
          }
        }}
        visibility="hidden"
        w="0.01px"
        h="0.01px"
        position="absolute"
        top="0px"
        left="-50%"
      />
    </>
  );
};

export default UploadFileInput;
