import React from "react";
import { SimpleGrid, SlideFade } from "@chakra-ui/react";
import { useHistory } from "react-router";

import ProjectCard from "./ProjectCard";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getAllProjects } from "./../../api/projects";
import { actions } from "../../redux/ProjectInfoSlice";

interface IProps {}

const Index: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();
  const projects = useAppSelector(
    (state) => state.projectInfoStorage.projectsList
  );
  const history = useHistory();
  const getData = async () => {
    try {
      const { data } = await getAllProjects();
      dispatch(actions.insertProjectInfo({ projectsList: data }));
      if (data.length === 1) {
        dispatch(actions.insertProjectInfo(data[0]));
        return history.push(`/${data[0].reference}`);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const activateProject = (project: any) =>
    dispatch(actions.insertProjectInfo(project));

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SimpleGrid columns={[1, 2, 3, 4]} spacing="3rem">
      {projects.map((project, index) => (
        <SlideFade
          in={true}
          offsetY="25%"
          delay={+`0.${index}5`}
          key={`projectid_${project.reference}_${index}`}
        >
          <ProjectCard
            {...project}
            activateProject={() => activateProject(project)}
          />
        </SlideFade>
      ))}
    </SimpleGrid>
  );
};

export default Index;
