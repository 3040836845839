import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Text,
  Box,
  Divider,
  Badge,
  Button,
  Flex,
} from "@chakra-ui/react";

import {
  convertDateToReadableString,
  downloadDocument,
  getLastDocumentVersion,
  getVersionId,
} from "./../../config/utils";
import { useAppSelector } from "../../redux/hooks";

interface IProps {
  isModalOpen: boolean;
  setIsModalOpen: (flag: boolean) => void;
}

const DocumentVersionsModal: React.FC<IProps> = (props) => {
  const { isModalOpen, setIsModalOpen } = props;
  const document = useAppSelector(
    (state) => state.documentRepository.activeDocument
  );
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    isModalOpen && onOpen();
    !isModalOpen && onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isModalOpen]);

  useEffect(() => {
    setIsModalOpen(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      isCentered
      variant="customModal"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>File versioning</ModalHeader>
        <ModalCloseButton />
        {document && (
          <ModalBody overflow="auto">
            {document.versions.map((version, index) => (
              <React.Fragment
                key={`versioningfile_${index}_${version.dateCreated}`}
              >
                <Flex w="100%">
                  <Box flex="1">
                    {version.version ===
                      getLastDocumentVersion(document.versions)?.version && (
                      <Badge variant="solid" colorScheme="green" mb="0.5rem">
                        Current
                      </Badge>
                    )}

                    <Text fontSize="small" color="black.800">
                      {convertDateToReadableString(
                        version.dateCreated!,
                        "full"
                      )}
                    </Text>
                    <Text fontSize="small" color="black.800">
                      {version.owner}
                    </Text>
                    <Button
                      variant="outlinePrimary"
                      mt="0.5rem"
                      onClick={(_) =>
                        downloadDocument(getVersionId(version.key!))
                      }
                    >
                      Get version
                    </Button>
                  </Box>
                  <Flex justifyContent="center" alignItems="center" flex="1">
                    <Text fontSize="small" color="black.900">
                      v. {version.version}
                    </Text>
                  </Flex>
                </Flex>
                <Divider margin="1rem 0" />
              </React.Fragment>
            ))}
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
};

export default DocumentVersionsModal;
