import React from "react";
import { Text, Flex, VStack, Code, Icon } from "@chakra-ui/react";
import { IoIosWarning } from "react-icons/io";

interface IProps {
  errorMessage: string;
}

const Index: React.FC<IProps> = (props) => {
  const { errorMessage } = props;
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      position="absolute"
      left="50%"
      top="50%"
      h="100vh"
      transform="translate(-50%)"
    >
      <VStack>
        <Icon as={IoIosWarning} />
        <Text fontSize="base" color="primary.main">
          Error! Please try again
        </Text>
        <Text fontSize="base" color="black.500">
          If the problem persists, please report to dhub@arup.com the following
          message:
        </Text>
        <Code>{errorMessage}</Code>
      </VStack>
    </Flex>
  );
};

export default Index;
