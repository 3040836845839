import { Input, Link, Tooltip } from "@chakra-ui/react";
import React from "react";
import { IAllowedAttachment } from ".";
import { IAttachedFile } from "../../redux/interfaces";

interface IProps {
  allowedAttachment: IAllowedAttachment;
  insertSelectedFile: (file: IAttachedFile | IAttachedFile[]) => void;
}

const UploadFile: React.FC<IProps> = (props) => {
  const { allowedAttachment, insertSelectedFile } = props;
  const inputRef: any = React.useRef();
  const onLinkClick = () => {
    inputRef.current.click();
  };

  return (
    <>
      <Link onClick={onLinkClick} fontSize="small">
        <Tooltip
          label="Hold CTRL key for selecting more than 1 file at once"
          aria-label="Upload new files instructions"
        >
          {allowedAttachment.label}
        </Tooltip>
      </Link>
      <Input
        ref={inputRef}
        type="file"
        multiple={true}
        onChange={(event) => {
          if (event.target.files) {
            insertSelectedFile(
              Array.from(event.target.files).map((file) => ({
                name: file.name,
                file,
                type: allowedAttachment.type,
              }))
            );
          }
        }}
        visibility="hidden"
        w="0.01px"
        h="0.01px"
        position="absolute"
        top="0px"
        left="-50%"
      />
    </>
  );
};

export default UploadFile;
