import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { setRfiStatus } from "../config/utils";

import { IRFI } from "./interfaces";

interface ISlice {
  rfiList: IRFI[];
  selectedRfiList: IRFI[];
  selectedRfi: IRFI | null;
}

const sliceInitialState: ISlice = {
  rfiList: [],
  selectedRfiList: [],
  selectedRfi: null,
};

const customSlice = createSlice({
  name: "rfi",
  initialState: sliceInitialState,
  reducers: {
    insertRfiList: (state, action: PayloadAction<IRFI[]>) => ({
      ...state,
      rfiList: applyModifiers(action.payload),
    }),
    insertNewRfi: (state, action: PayloadAction<IRFI>) => ({
      ...state,
      rfiList: applyModifiers([action.payload, ...state.rfiList]),
    }),
    resetRfiList: (state) => ({ ...state, rfiList: [] }),
    insertSelectedRfi: (state, action: PayloadAction<IRFI>) => ({
      ...state,
      selectedRfi: action.payload,
    }),
    resetSelectedRfi: (state) => ({
      ...state,
      selectedRfi: null,
    }),
    insertSelectedRfiList: (state, action: PayloadAction<IRFI[]>) => ({
      ...state,
      selectedRfiList: action.payload,
    }),
  },
});

const applyModifiers = (rfiList: IRFI[]) =>
  rfiList.sort(orderByCreationDate).map(setRfiStatus);

const orderByCreationDate = (a: IRFI, b: IRFI) => {
  if (!a.date || !b.date || a.date === b.date) return 0;

  return a.date > b.date ? 1 : -1;
};

export const actions = customSlice.actions;
export default customSlice.reducer;
