import axios from "axios";

import config from "./../config/env";
import { IRFI, IRfiResponse } from "./../redux/interfaces";

export const getAllRfi = () => axios.get(`${config.API}/rfis`);

export const postNewRfi = (newRfi: IRFI) =>
  axios.post(`${config.API}/rfi`, newRfi);

export const sendNewMessage = (message: IRfiResponse) =>
  axios.put(`${config.API}/rfi`, message);

export const deleteRfi = (id: number) => {
  return axios.delete(`${config.API}/rfi`, { params: { id: id } });
};
