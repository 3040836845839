import React from "react";
import { Column } from "react-table";
import { Text } from "@chakra-ui/react";

import { convertDateToReadableString } from "../../../config/utils";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { IRFI } from "../../../redux/interfaces";
import { actions as rfiActions } from "../../../redux/RfiSlice";

import Table, { IColumnProperties } from "../../../shared/Table";
import RfiStatusIndicator from "../../../shared/RfiStatusIndicator";
import RfiCriticalndicator from "../../../shared/RfiCriticalndicator";

interface IProps {}

const Index: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();
  const data: IRFI[] = useAppSelector(
    (state) => state.rfiStorage.selectedRfiList
  );

  const [selectedRow, setSelectedRow] = React.useState(0);

  const setSelectedRfi = (row: any) => {
    setSelectedRow(row);
    dispatch(rfiActions.insertSelectedRfi(data[row.index]));
  };

  const columns = React.useMemo<Column<IRFI>[]>(
    () => [
      {
        id: "1",
        Header: "Num.",
        accessor: "id",
      },
      {
        id: "2",
        Header: "Discipline",
        accessor: "discipline",
      },
      {
        id: "3",
        Header: "Reg. date",
        accessor: "date",
      },
      {
        id: "4",
        Header: "Sent to",
        accessor: "departments",
      },
      {
        id: "5",
        Header: "Subject",
        accessor: "subject",
      },
      {
        id: "6",
        Header: "Status",
        accessor: "status",
      },
      {
        id: "7",
        Header: "Critical",
        accessor: "critical",
      },
    ],
    []
  );

  const customColumns: IColumnProperties[] = [
    {
      props: {
        fontSize: "small",
        color: "primary.main",
      },
      customRenderer: (cell: any) => cell.value.toString().padStart(3, "0"),
    },
    {
      props: {
        fontSize: "small",
        color: "black.600",
      },
    },
    {
      props: {
        fontSize: "small",
        color: "black.600",
      },
      customRenderer: (cell: any) =>
        convertDateToReadableString(cell.value, "onlyDate"),
    },
    {
      props: {
        fontSize: "small",
        color: "black.600",
      },
      customRenderer: (cell: any) => cell.value.join(", "),
    },
    {
      props: {
        fontSize: "small",
        color: "black.100",
        fontFamily: "Times New Roman",
        letterSpacing: "0.20px",
      },
      customRenderer: (cell: any) => (
        <Text isTruncated maxWidth="90%">
          {cell.render("Cell")}
        </Text>
      ),
    },
    {
      props: {
        fontSize: "small",
        color: "black.600",
      },
      customRenderer: (cell: any) => (
        <RfiStatusIndicator rfiStatus={cell.value} />
      ),
    },
    {
      props: {
        fontSize: "small",
        color: "black.600",
      },
      customRenderer: (cell: any) => (
        <RfiCriticalndicator critical={!!cell.value} />
      ),
    },
  ];

  return (
    <Table
      data={data}
      columns={columns}
      customColumns={customColumns}
      selectedRow={selectedRow}
      setSelectedRow={setSelectedRfi}
    />
  );
};

export default Index;
