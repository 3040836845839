import React from "react";
import {
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  List,
  ListItem,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
} from "@chakra-ui/react";
import { FiSearch } from "react-icons/fi";

import { IAllowedAttachment } from "./index";
import { IAttachedFile } from "../../redux/interfaces";

interface IProps {
  allowedAttachment: IAllowedAttachment;
  selectedFiles: IAttachedFile[];
  insertSelectedFile: (file: IAttachedFile) => void;
}

const SelectFileFromOptions: React.FC<IProps> = (props) => {
  const { allowedAttachment, selectedFiles, insertSelectedFile } = props;
  const { options } = allowedAttachment;
  const [isPopoverOpen, setIsPopoverOpen] = React.useState(false);
  const [optionsFiltered, setOptionsFiltered] = React.useState<IAttachedFile[]>(
    []
  );

  const handleOnInputChange = (event) => {
    const searchCriteria: string = event.target.value.toLowerCase();
    setOptionsFiltered(
      options!.filter((option) =>
        option.name.toLowerCase().includes(searchCriteria)
      )
    );
    if (searchCriteria.length >= 1) return setIsPopoverOpen(true);

    setIsPopoverOpen(false);
  };

  return (
    <Popover
      returnFocusOnClose={false}
      isOpen={isPopoverOpen}
      closeOnBlur={false}
      autoFocus={false}
      placement="bottom-start"
    >
      <PopoverTrigger>
        <InputGroup>
          <InputLeftElement pointerEvents="none" children={<FiSearch />} />
          <Input
            name="link_file_from_storage"
            type="text"
            onKeyUp={handleOnInputChange}
            autoComplete={"off"}
            fontSize="sm"
          />
        </InputGroup>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverBody bg="white.500">
          <List>
            {optionsFiltered.length === 0 && (
              <Text fontSize="sm">No files found</Text>
            )}

            {optionsFiltered.map((doc) => (
              <React.Fragment key={`list_${doc.id}`}>
                <ListItem
                  {...(selectedFiles!.findIndex((sF) => sF.id === doc.id) >
                    -1 && {
                    bg: "white.100",
                  })}
                  onClick={(_) => {
                    insertSelectedFile({
                      id: (doc as any).key?.split("/")[1] || doc.id,
                      name: doc.name,
                      type: allowedAttachment.type,
                    });
                    setIsPopoverOpen(false);
                  }}
                  p="0.5rem"
                  _hover={{
                    cursor: "pointer",
                    bg: "white.400",
                  }}
                >
                  <Text fontSize="small">{doc.name}</Text>
                </ListItem>
                <Divider w="100%" />
              </React.Fragment>
            ))}
          </List>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default SelectFileFromOptions;
