import { Box } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";

import SearchBox from "./SearchBox";
import CategoryList from "./CategoryList/CategoryList";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { actions } from "../../redux/DocumentRepositorySlice";
import { splitPrefix } from "../../config/utils";

interface IProps {}

const LeftSidebar: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();
  const [filters, setFilters] = useState({
    searchBox: "",
    category: "",
  });

  const [selectedCategory, setSelectedCategory] = useState("");

  const documentRepository = useAppSelector(
    (state) => state.documentRepository
  );

  const applyFilters = () => {
    const search = filters.searchBox.toLowerCase();
    if (filters.category) {
      setSelectedCategory(filters.category);
    }
    if (search.length > 0) {
      filters.category = "";
    } else if (!filters.category) {
      filters.category = selectedCategory;
    }
    dispatch(
      actions.insertFilteredDocumentsList(
        documentRepository.documentsList.filter((doc) => {
          if (search.length > 0) {
            return (
              doc.name.toLowerCase().includes(search) ||
              doc.prefix.toLowerCase().includes(search) ||
              doc.owner.toLowerCase().includes(search)
            );
          }
          return splitPrefix(doc.prefix)[0] === filters.category;
        })
      )
    );
  };

  useEffect(() => {
    setFilters({
      ...filters,
      category: documentRepository.categoriesList[0]?.label,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentRepository.categoriesList]);

  useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, documentRepository.documentsList]);

  return (
    <Box w="21rem" mr="28">
      <SearchBox activeFilters={filters} setActiveFilters={setFilters} />
      <CategoryList
        categoriesList={documentRepository.categoriesList}
        activeFilters={filters}
        setActiveFilters={setFilters}
      />
    </Box>
  );
};

export default LeftSidebar;
