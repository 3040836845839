import { Box, Text } from "@chakra-ui/layout";
import React from "react";

interface IProps {
  categoryName: string;
  isSelected: boolean;
  handleClick: (categoryName: string) => void;
}

const CategoryCard: React.FC<IProps> = (props) => {
  const { categoryName, isSelected, handleClick } = props;
  return (
    <Box
      bg={isSelected ? "primary.main" : "white.500"}
      w="100%"
      p="0.75rem 1.5rem"
      mb="0.5rem"
      borderRadius="md"
      _hover={{
        boxShadow: "sm",
        cursor: "pointer",
        borderLeft: ".5rem solid",
        borderColor: "primary.main",
      }}
      onClick={(_) => handleClick(categoryName)}
    >
      <Text fontSize="small" color={isSelected ? "white.500" : "black.100"}>
        {categoryName}
      </Text>
    </Box>
  );
};

export default CategoryCard;
