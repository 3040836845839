import axios from "axios";
import {
  ICategory,
  IDeliverableInput,
  IRawDeliverableResponse,
} from "./../redux/interfaces";
import { v4 as uuidv4 } from "uuid";
import { IAttachedFile } from "../redux/interfaces";

import config from "./../config/env";

export const getAllDeliverables = () =>
  axios.get<IRawDeliverableResponse[]>(`${config.API}/deliverables`);

export const getAllCategories = () =>
  axios.get<ICategory[]>(`${config.API}/categories`, {});

export const createDeliverable = (deliverable: IDeliverableInput) =>
  axios.post(`${config.API}/deliverable`, deliverable);

export const uploadDocument = async (file: File, id: string) => {
  const { data } = await getUploadSignedUrl(id, file.type, file.name);
  return axios.put(`${data}`, file, {
    headers: { "Content-Type": file.type },
  });
};

const getUploadSignedUrl = (
  key: string,
  contentType: string,
  fileName: string
) => {
  return axios.get<string>(`${config.API}/putSignedUrl`, {
    params: {
      key,
      contentType: encodeURI(contentType),
      fileName: encodeURI(fileName),
    },
  });
};

export const uploadFiles = async (filesToUpload: IAttachedFile[]) => {
  const filesUploaded: IAttachedFile[] = [];

  if (filesToUpload.length)
    await Promise.allSettled(
      filesToUpload.map((file) => {
        const id = `rfi/${uuidv4()}`;
        return uploadDocument(file.file!, id).then((_) =>
          filesUploaded.push({
            id,
            name: file.name,
            type: file.type,
          })
        );
      })
    );

  return filesUploaded;
};
