import React from "react";
import { Flex, Icon } from "@chakra-ui/react";
import { IoIosCheckmark, IoIosClose } from "react-icons/io";

interface IProps {
  status: boolean;
}

const IssueStatusIndicator: React.FC<IProps> = (props) => {
  const { status } = props;

  const setIconBackgroundByStatus = (status: boolean) => {
    if (!status) return "primary.main";
    else if (status) return "green.dark";
    return "transparent";
  };

  return (
    <Flex
      p="0.1rem"
      borderRadius="50%"
      border="1px solid"
      borderColor="black.900"
      alignItems="center"
      justifyContent="center"
      w="2rem"
      h="2rem"
    >
      <Flex
        w="100%"
        h="100%"
        borderRadius="50%"
        border="1px solid"
        alignItems="center"
        justifyContent="center"
        background={setIconBackgroundByStatus(status)}
        color="white.500"
      >
        {!status && (
          <Icon as={IoIosClose} color="white.500" fontSize="1.5rem" />
        )}
        {status && (
          <Icon as={IoIosCheckmark} color="white.500" fontSize="1.5rem" />
        )}
      </Flex>
    </Flex>
  );
};

export default IssueStatusIndicator;
