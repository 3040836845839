import React from "react";
import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  HStack,
  Text,
  Textarea,
  useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { Form, Formik, Field } from "formik";

import { IAttachedFile } from "../../redux/interfaces";
import AttachFiles, { IAllowedAttachment } from "../AttachFiles";
import AttachedFilesList from "../AttachFiles/AttachedFilesList";
import StatusSelector from "./StatusSelector";

interface IFormValues {
  messageText: string;
  status?: string;
}
interface IProps {
  type: "Request" | "Answer" | "Clarification" | "Clarification Answer";
  allowedAttachments?: IAllowedAttachment[];
  statusOptions?: any[];
  submitNewMessage: (files, values) => void;
}

const WriteMessage: React.FC<IProps> = (props) => {
  const { type, submitNewMessage, allowedAttachments, statusOptions } = props;
  const [attachedFiles, setAttachedFiles] = React.useState<IAttachedFile[]>([]);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const toast = useToast();

  const initialFormValues: IFormValues = {
    messageText: "",
    ...(!!statusOptions && { status: "" }),
  };

  const FormSchema = Yup.object().shape({
    messageText: Yup.string().required("Required field"),
    ...(!!statusOptions &&
      type === "Answer" && { status: Yup.string().required() }),
  });

  const onSubmit = async (values: IFormValues, { resetForm }) => {
    setIsSubmitting(true);

    try {
      await submitNewMessage(attachedFiles, values);
    } catch (err) {
      console.log(err);
      toast({
        title: "An error occurred",
      });
    } finally {
      setIsSubmitting(false);
    }
    resetForm({ values: "" });
  };

  return (
    <Box w="100%">
      <Text fontSize="small" mb="0.4rem">
        {type}
      </Text>
      <Formik
        initialValues={initialFormValues}
        validationSchema={FormSchema}
        onSubmit={onSubmit}
      >
        {(formProps) => (
          <Form>
            <Field name="messageText">
              {({ field, form }: any) => (
                <FormControl
                  isInvalid={
                    form.errors.messageText && form.touched.messageText
                  }
                  mb="1rem"
                >
                  <Textarea {...field} id="messageText" fontSize="small" />
                  <FormErrorMessage>{form.errors.messageText}</FormErrorMessage>
                </FormControl>
              )}
            </Field>

            {!!allowedAttachments && (
              <>
                <AttachedFilesList
                  attachedFiles={attachedFiles}
                  setAttachedFiles={setAttachedFiles}
                />
                <AttachFiles
                  attachedFiles={attachedFiles}
                  setAttachedFiles={setAttachedFiles}
                  allowedAttachments={allowedAttachments}
                />
              </>
            )}

            {type === "Answer" && !!statusOptions && (
              <Field name="status">
                {({ field, form }: any) => (
                  <FormControl
                    isInvalid={form.errors.status && form.touched.status}
                    mb="1rem"
                  >
                    <StatusSelector
                      options={statusOptions}
                      onChange={(value: string) =>
                        form.setValues({ ...form.values, status: value })
                      }
                    />
                    <FormErrorMessage>{form.errors.status}</FormErrorMessage>
                  </FormControl>
                )}
              </Field>
            )}

            <HStack w="100%" justifyContent="flex-end">
              <Button
                variant="solidPrimary"
                type="submit"
                isLoading={isSubmitting}
                loadingText="Sending answer"
              >
                Post message
              </Button>
            </HStack>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default WriteMessage;
