import React from "react";
import { Flex, Text } from "@chakra-ui/react";

interface IProps {
  priority: number;
}

const IssueStatusIndicator: React.FC<IProps> = (props) => {
  const { priority } = props;

  const setIconBackgroundByRfiStatus = (priority: number) => {
    if (priority === 1) return "black.300";
    else if (priority === 2) return "black.900";
    return "transparent";
  };

  return (
    <Flex
      p="0.2rem"
      borderRadius="50%"
      border="1px solid"
      borderColor="black.900"
      alignItems="center"
      justifyContent="center"
      w="2rem"
      h="2rem"
    >
      {priority !== 3 && (
        <Flex
          w="100%"
          h="100%"
          borderRadius="50%"
          border="1px solid"
          borderColor="black.900"
          alignItems="center"
          justifyContent="center"
          background={setIconBackgroundByRfiStatus(priority)}
        >
          <Text color="white.500" fontSize="0.7rem">
            {priority}
          </Text>
        </Flex>
      )}
      {priority === 3 && (
        <Text color="black.600" fontSize="0.7rem">
          {priority}
        </Text>
      )}
    </Flex>
  );
};

export default IssueStatusIndicator;
