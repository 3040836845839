import React from "react";
import { sub, isAfter, isBefore } from "date-fns";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { actions } from "../../redux/IssuesSlice";
import { IFilter } from "../../shared/FiltersSideBar/FilterGroup";

import FilterPane from "../../shared/FiltersSideBar/FilterPane";

interface IProps {}

const Filters: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();
  const disciplines = useAppSelector((state) => state.disciplines.disciplines);
  const issueList = useAppSelector((state) => state.issuesStorage.issueList);

  const [disciplinesFilter, setDisciplinesFilter] = React.useState<IFilter[]>(
    []
  );
  const [statusFilter, setStatusFilter] = React.useState<IFilter[]>([]);
  const [priorityFilter, setPriorityFilter] = React.useState<IFilter[]>([]);
  const [dateFilter, setDateFilter] = React.useState<IFilter[]>([]);

  const applyFilters = () => {
    let modifiedList = [...issueList];

    // eslint-disable-next-line array-callback-return
    modifiedList = modifiedList.filter((item) => {
      if (
        disciplinesFilter.find((filter) => filter.key === item.discipline)
          ?.isActive
      )
        return item;
    });
    // eslint-disable-next-line array-callback-return
    modifiedList = modifiedList.filter((item) => {
      if (
        priorityFilter.find((filter) => filter.key === item.priority)?.isActive
      )
        return item;
    });
    // eslint-disable-next-line array-callback-return
    modifiedList = modifiedList.filter((item) => {
      if (statusFilter.find((filter) => filter.key === item.status)?.isActive)
        return item;
    });

    // eslint-disable-next-line array-callback-return
    modifiedList = modifiedList.filter((item) => {
      if (
        dateFilter.find((filter) => filter.key === "NEW")?.isActive &&
        isAfter(new Date(item.date!), sub(new Date(), { weeks: 2 }))
      )
        return item;
      if (
        dateFilter.find((filter) => filter.key === "OLD")?.isActive &&
        isBefore(new Date(item.date!), sub(new Date(), { weeks: 2 })) &&
        isAfter(new Date(item.date!), sub(new Date(), { weeks: 8 }))
      )
        return item;
      if (
        dateFilter.find((filter) => filter.key === "VOLD")?.isActive &&
        isBefore(new Date(item.date!), sub(new Date(), { weeks: 8 }))
      )
        return item;
    });
    dispatch(actions.insertSelectedList(modifiedList));
  };

  React.useEffect(() => {
    applyFilters();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [issueList, disciplinesFilter, statusFilter, priorityFilter, dateFilter]);

  React.useEffect(() => {
    if (!!disciplines.length) {
      setDisciplinesFilter(
        disciplines.map((discipline) => ({
          label: discipline,
          key: discipline,
          quantity: issueList.filter((item) => item.discipline === discipline)
            .length,
          isActive: true,
        }))
      );
      setPriorityFilter([
        {
          label: "High (1)",
          quantity: issueList.filter((item) => item.priority === 1).length,
          key: 1,
          isActive: true,
        },
        {
          label: "Medium (2)",
          quantity: issueList.filter((item) => item.priority === 2).length,
          key: 2,
          isActive: true,
        },
        {
          label: "Low (3)",
          quantity: issueList.filter((item) => item.priority === 3).length,
          key: 3,
          isActive: true,
        },
      ]);
      setStatusFilter([
        {
          label: "Open",
          quantity: issueList.filter((item) => !item.status).length,
          key: false,
          isActive: true,
          color: "primary.main",
        },
        {
          label: "Solved",
          quantity: issueList.filter((item) => item.status).length,
          isActive: true,
          key: true,
        },
      ]);
      setDateFilter([
        {
          label: "New ( < 2 weeks)",
          quantity: issueList.filter((item) =>
            isAfter(new Date(item.date!), sub(new Date(), { weeks: 2 }))
          ).length,
          key: "NEW",
          isActive: true,
        },
        {
          label: "Old ( 2-8 weeks)",
          quantity: issueList.filter(
            (item) =>
              isBefore(new Date(item.date!), sub(new Date(), { weeks: 2 })) &&
              isAfter(new Date(item.date!), sub(new Date(), { weeks: 8 }))
          ).length,
          key: "OLD",
          isActive: true,
        },
        {
          label: "Very old ( > 8 weeks)",
          quantity: issueList.filter((item) =>
            isBefore(new Date(item.date!), sub(new Date(), { weeks: 8 }))
          ).length,
          key: "VOLD",
          isActive: true,
        },
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disciplines, issueList]);

  return (
    <FilterPane
      filterGroups={[
        {
          filterKey: "discipline",
          filterOptions: disciplinesFilter,
          filterSetter: setDisciplinesFilter,
          title: "Disciplines",
        },
        {
          filterKey: "priority",
          filterOptions: priorityFilter,
          filterSetter: setPriorityFilter,
          title: "Priority",
        },
        {
          filterKey: "status",
          filterOptions: statusFilter,
          filterSetter: setStatusFilter,
          title: "Status",
        },
        {
          filterKey: "registration",
          filterOptions: dateFilter,
          filterSetter: setDateFilter,
          title: "Registration",
        },
      ]}
    />
  );
};

export default Filters;
