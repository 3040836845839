import axios from "axios";

import config from "./../config/env";

export const getIssues = () => axios.get(`${config.API}/issues`);

export const putIssues = (issuesId: number, status: boolean) =>
  axios.put(`${config.API}/issues/${issuesId}`, { status: status });

export const deleteIssues = (issuesId: number) =>
  axios.delete(`${config.API}/issues/${issuesId}`);
