import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  Input,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { Divider } from "@chakra-ui/react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import UploadFileInput from "./UploadFileInput";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getLastDocumentVersion,
  getVersionId,
  splitPrefix,
} from "../../config/utils";
import { actions } from "../../redux/DocumentRepositorySlice";
import { ICategory } from "../../redux/interfaces";
import { createDeliverable, uploadDocument } from "../../api/documents";

const MB_ALLOWED = 2048;
const FILE_SIZE = 1024 * MB_ALLOWED * 1024; //  Convert allowed MB to KB and then to bytes
// const SUPPORTED_FORMATS = ["image/jpg", "image/jpeg", "image/gif", "image/png"];

interface IFormValues {
  category: string;
  subcategory: string;
  title: string;
  file: any;
}

const initialFormValues: IFormValues = {
  category: "",
  subcategory: "",
  title: "",
  file: null,
};

const FormSchema = Yup.object().shape({
  category: Yup.string().required("Category is required"),
  subcategory: Yup.string().required("Subcategory is required"),
  title: Yup.string().required("Title is required"),
  file: Yup.mixed()
    .notRequired()
    .test(
      "fileSize",
      `Maximum file size of ${MB_ALLOWED} MB exceeded`,
      (value) => {
        if (!!value) return value.size <= FILE_SIZE;
        return true;
      }
    ),
  // .test("fileType", "Unsupported File Format", (value) => {
  //   if (!!value) SUPPORTED_FORMATS.includes(value?.type);
  //   return true;
  // }),
});

interface IProps {
  isUploadDocumentModalOpen: boolean;
  setIsUploadDocumentModalOpen: (flag: boolean) => void;
}

const UploadDocumentModal: React.FC<IProps> = (props) => {
  const { isUploadDocumentModalOpen, setIsUploadDocumentModalOpen } = props;
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [elegiblesSubcategories, setElegiblesSubcategories] = React.useState<
    ICategory[]
  >([]);
  const documentRepository = useAppSelector(
    (state) => state.documentRepository
  );
  const user = useAppSelector((state) => state.userStorage.currentUser.email);
  const toast = useToast();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const onSubmit = async (values: IFormValues, _: any) => {
    const { category, subcategory, title, file } = values;
    if (!documentRepository.activeDocument && !file) {
      toast({
        title: "Please attach a file",
        status: "error",
      });
      return;
    }
    const { data } = await createDeliverable({
      ...(documentRepository.activeDocument && {
        id: documentRepository.activeDocument?.id,
      }),
      name: title,
      ...(file && { version: { owner: user } }),
      prefix: `${category}>${subcategory}`,
    });
    dispatch(actions.insertOrUpdateDocument(data));
    if (file) {
      setIsLoading(true);
      const versionId = getVersionId(
        getLastDocumentVersion(Array.from(data.versions))!.key!
      );

      toast({
        title: "Uploading document. Please wait",
        status: "warning",
      });
      uploadDocument(file, versionId).then(() => {
        toast.closeAll();
        toast({
          title: "Document successfully uploaded",
          status: "success",
        });
        setIsLoading(false);
        setIsUploadDocumentModalOpen(false);
      });
    }
  };

  const onCategoryChange = (event: any, formProps: any) => {
    formProps.setFieldValue("category", event.target.value);
    formProps.setFieldValue("subcategory", "");
    setElegiblesSubcategories(
      documentRepository.categoriesList.find(
        (cat) => cat.label === event.target.value
      )?.children || []
    );
  };

  const getInitialValues = () =>
    documentRepository.activeDocument
      ? {
          category: splitPrefix(documentRepository.activeDocument.prefix)[0],
          subcategory: splitPrefix(documentRepository.activeDocument.prefix)[1],
          title: documentRepository.activeDocument.name,
          file: null,
        }
      : initialFormValues;

  useEffect(() => {
    if (isUploadDocumentModalOpen) {
      onOpen();
      if (documentRepository.activeDocument)
        setElegiblesSubcategories(
          documentRepository.categoriesList.find(
            (cat) =>
              cat.label ===
              splitPrefix(documentRepository.activeDocument!.prefix)[0]
          )?.children || []
        );
    }
    !isUploadDocumentModalOpen && onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUploadDocumentModalOpen]);

  useEffect(() => {
    setIsUploadDocumentModalOpen(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered variant="customModal">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {documentRepository.activeDocument
            ? "Upload new version"
            : "Upload new file"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={getInitialValues()}
            validationSchema={FormSchema}
            onSubmit={onSubmit}
          >
            {(formProps) => (
              <Form>
                <Field name="category">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={form.errors.category && form.touched.category}
                    >
                      <FormLabel htmlFor="category">Category</FormLabel>
                      <Select
                        {...field}
                        id="category"
                        onChange={(event) => onCategoryChange(event, formProps)}
                      >
                        <option value="" disabled={true}>
                          Select category
                        </option>
                        {documentRepository.categoriesList.map((category) => (
                          <option
                            value={category.label}
                            key={`category_${category.id}`}
                          >
                            {category.label}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {form.errors.category}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="subcategory">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={
                        form.errors.subcategory && form.touched.subcategory
                      }
                      mt="1rem"
                    >
                      <FormLabel htmlFor="subcategory">Subcategory</FormLabel>
                      <Select
                        {...field}
                        id="subcategory"
                        disabled={!!!formProps.values.category}
                      >
                        <option value="" disabled={true}>
                          {!!formProps.values.category
                            ? "Select subcategory"
                            : "Select category first"}
                        </option>
                        {elegiblesSubcategories &&
                          elegiblesSubcategories.map((subcategory) => (
                            <option
                              value={subcategory.label}
                              key={`subcategory_${subcategory.id}_${subcategory.label}`}
                            >
                              {subcategory.label}
                            </option>
                          ))}
                      </Select>
                      <FormErrorMessage>
                        {form.errors.subcategory}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="title">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={form.errors.title && form.touched.title}
                      mt="1rem"
                    >
                      <FormLabel htmlFor="title">Title</FormLabel>
                      <Input {...field} id="title" />
                      <FormErrorMessage>{form.errors.title}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="file">
                  {({ _, form }: any) => (
                    <FormControl
                      isInvalid={form.errors.file && form.touched.file}
                      mt="1rem"
                    >
                      <UploadFileInput formikFormProp={formProps} />
                      <FormErrorMessage>{form.errors.file}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Divider margin="1.25rem 0" />
                <HStack w="100%" justifyContent="flex-end">
                  <Button
                    isLoading={isLoading}
                    variant="solidPrimary"
                    type="submit"
                  >
                    Submit
                  </Button>
                </HStack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default UploadDocumentModal;
