import React from "react";
import { Flex, Icon } from "@chakra-ui/react";
import { IoIosCheckmark } from "react-icons/io";

import { RfiStatusType } from "../redux/interfaces";

interface IProps {
  rfiStatus: RfiStatusType;
}

const RfiStatusIndicator: React.FC<IProps> = (props) => {
  const { rfiStatus } = props;

  const setIconBackgroundByRfiStatus = (status: RfiStatusType) => {
    if (status === "pendingApproval") return "black.900";
    else if (status === "closed") return "black.300";
    return "transparent";
  };

  return (
    <Flex
      p="0.3rem"
      borderRadius="50%"
      border="1px solid"
      borderColor="black.900"
      alignItems="center"
      justifyContent="center"
      w="2rem"
      h="2rem"
    >
      {rfiStatus !== "pendingResponse" && (
        <Flex
          w="1.5rem"
          h="1.5rem"
          borderRadius="50%"
          border="1px solid"
          borderColor="black.900"
          alignItems="center"
          justifyContent="center"
          background={setIconBackgroundByRfiStatus(rfiStatus)}
        >
          <Icon as={IoIosCheckmark} color="white.500" fontSize="1.5rem" />
        </Flex>
      )}
    </Flex>
  );
};

export default RfiStatusIndicator;
