import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
  baseStyle: {
    fontSize: "sm",
    color: "black.600",
    margin: "0 0 0.3125rem 0",
    letterSpacing: "0.2px",
  },
  sizes: {},
  variants: {},
  defaultProps: {
    size: "md",
  },
};

export default componentOverride;
