import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Tooltip,
  Spinner,
} from "@chakra-ui/react";
import { BiDownload } from "react-icons/bi";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import PageTitle from "../PageTitle";
import Filters from "./Filters";
import { getAllDisciplines } from "./../../api/disciplines";
import CustomTable from "./Table";
import AddNewRequest from "./Modals/AddNewRequest";
import ConversationView from "../../shared/ConversationModal";
import MessageViewWithStatus from "../../shared/ConversationModal/MessageViews/MessageViewWithStatus";

import { actions as disciplineActions } from "../../redux/DisciplineSlice";
import { actions as submittalActions } from "../../redux/SubmittalSlice";
import {
  IAttachedFile,
  IDept,
  ISubmittal,
  ISubmittalResponse,
} from "../../redux/interfaces";
import { uploadFiles } from "../../api/documents";
import { getDepartments } from "../../api/departments";
import { getAllSubmittals } from "../../api/submittals";
import { openSubmittal, sendNewSubmittal } from "../../api/submittals";
import { setMessageType, downloadDocument } from "../../config/utils";
import WriteMessage from "../../shared/ConversationModal/WriteMessage";
import SubmittalStatusIndicator from "../../shared/SubmittalStatusIndicator";
import { useFetchStatus } from "../../hooks";

interface IProps {}

const Index: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useFetchStatus(true);
  const submittalStorage = useAppSelector((state) => state.submittalStorage);
  const userStorage = useAppSelector((state) => state.userStorage);
  const rfiList = useAppSelector((state) => state.rfiStorage.rfiList);

  const [isAddNewRequestOpen, setIsAddNewRequest] = React.useState(false);
  const [departmentList, setDepartmentList] = React.useState<IDept[]>([]);

  const options = [
    { value: "A", color: "green.dark" },
    { value: "B", color: "yellow.dark" },
    { value: "C", color: "primary.main" },
  ];

  const getData = async () => {
    try {
      setLoading(true);
      const disciplinesResponse = await getAllDisciplines();
      const submittalResponse = await getAllSubmittals();
      dispatch(
        disciplineActions.insertDisciplines(
          disciplinesResponse.data.disciplines
        )
      );
      dispatch(submittalActions.insertList(submittalResponse.data));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    dispatch(submittalActions.resetSelected());
  };

  const create = async (values: any, files: IAttachedFile[]) => {
    const { discipline, subject, question, sendTo, critical } = values;
    const toUpload = files.filter((af) => af.file);
    let uploadedfiles = files.filter((af) => !af.file);
    uploadedfiles = [...uploadedfiles, ...(await uploadFiles(toUpload))];
    const newSubmittal: ISubmittal = {
      discipline: discipline,
      departments: sendTo.map((x) => x.name),
      recipients: departmentList
        .filter((x) => sendTo.map((x) => x.name).includes(x.name))
        .map((x) => x.id),
      subject,
      critical,
      closed: false,
      conversation: [
        {
          message: question,
          posted_by: userStorage.currentUser.email,
          files: [
            ...uploadedfiles.map((aF) => ({
              id: aF.id!,
              name: aF.name,
              type: aF.type,
            })),
          ],
        },
      ],
    };

    const response = await openSubmittal(newSubmittal);
    dispatch(submittalActions.insertNew(response.data));
    dispatch(submittalActions.insertSelected(response.data));
  };

  const sendNewResponse = async (files: IAttachedFile[], values: any) => {
    const toUpload = files.filter((af) => af.file);
    let uploadedFiles = files.filter((af) => !af.file);
    const response = await uploadFiles(toUpload);
    uploadedFiles = [...uploadedFiles, ...response];

    const type = setMessageType(
      submittalStorage.selectedSubmittal!.conversation.length
    );
    const newMessage: ISubmittalResponse = {
      id: submittalStorage.selectedSubmittal!.id!,
      message: {
        message: values.messageText,
        posted_by: userStorage.currentUser.email,
        ...(type === "Answer" && { status: values.status }),
        files: [
          ...uploadedFiles.map((aF) => ({
            id: aF.id!,
            name: aF.name,
            type: aF.type,
          })),
        ],
      },
      ...(values.status === "A" && { closed: true }),
    };
    const { data } = await sendNewSubmittal(newMessage);
    const rfiUpdated = data;
    const newList = [...submittalStorage.submittalList];
    const index = submittalStorage.submittalList.findIndex(
      (x) => x.id === rfiUpdated.id
    );
    newList.splice(index, 1, rfiUpdated);
    dispatch(submittalActions.insertList(newList));
    dispatch(submittalActions.insertSelected(rfiUpdated));
  };

  const show = () => {
    const type = setMessageType(
      submittalStorage.selectedSubmittal!.conversation.length
    );
    const closed = submittalStorage.selectedSubmittal!.closed;
    if (type === "Answer" && !closed) return true;
    else if (type === "Request" && !closed) return true;
    return false;
  };

  useEffect(() => {
    getData();
    getDepartments().then((res) =>
      setDepartmentList(
        res.data.map((dept) => ({ ...dept, label: dept.name, value: dept.id }))
      )
    );
    // TODO: get rfi
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flexDirection="column" h="100%">
      <Flex justifyContent="space-between" alignItems="top" mb="1.25rem">
        <PageTitle title="Submittals" />
        <Box>
          <Tooltip label="Download submittals template" aria-label="tooltip">
            <IconButton
              variant="noBackground"
              icon={<BiDownload />}
              aria-label="Download submittals template"
              fontSize="2xl"
              color="primary.main"
              onClick={(_) => {
                downloadDocument("submittals/SUBMITTAL FORM.docx");
              }}
            />
          </Tooltip>

          <Button
            variant="solidPrimary"
            onClick={() => {
              setIsAddNewRequest(true);
            }}
          >
            Add submittal
          </Button>
        </Box>
      </Flex>

      <Flex alignItems="top" flex="1" maxH="95%">
        <Filters />
        <Box flex="1.25">
          {loading && (
            <Spinner
              color="primary.main"
              position="absolute"
              left="50%"
              top="50%"
              transform="translate(-50%)"
            />
          )}
          {!loading && <CustomTable />}
        </Box>
        {!!submittalStorage.selectedSubmittal && (
          <Box flex="1" ml="1.5rem" mt="1.5rem">
            <ConversationView
              selectedConversation={submittalStorage.selectedSubmittal}
              onClose={onClose}
              indicator={
                <SubmittalStatusIndicator
                  status={submittalStorage.selectedSubmittal!.status!}
                />
              }
            >
              {submittalStorage.selectedSubmittal.conversation.map(
                (message, index) => (
                  <MessageViewWithStatus
                    options={options}
                    key={`message_${index}`}
                    message={message}
                    type={setMessageType(index)}
                    conversation={submittalStorage.selectedSubmittal}
                    index={index}
                  />
                )
              )}
              {show() && (
                <WriteMessage
                  submitNewMessage={sendNewResponse}
                  type={setMessageType(
                    submittalStorage.selectedSubmittal.conversation.length
                  )}
                  allowedAttachments={[
                    {
                      label: "Upload new file",
                      type: "newUpload",
                    },
                    {
                      label: "Link RFI",
                      type: "innerLink",
                      options: rfiList.map((rfi) => ({
                        id: `/rfi/${rfi.id}`,
                        name: rfi.subject,
                      })),
                    },
                  ]}
                  statusOptions={options}
                />
              )}
              <Divider />
            </ConversationView>
          </Box>
        )}
      </Flex>
      <AddNewRequest
        title="submittal"
        isAddNewRequestOpen={isAddNewRequestOpen}
        setIsAddNewRequest={setIsAddNewRequest}
        submit={create}
        sendToList={departmentList}
        isAttachmentsAllowed
        allowedAttachments={[
          {
            label: "Upload submittal form",
            type: "newUpload",
          },
          {
            label: "Link RFI",
            type: "innerLink",
            options: rfiList.map((rfi) => ({
              id: `/rfi/${rfi.id}`,
              name: rfi.subject,
            })),
          },
        ]}
      />
    </Flex>
  );
};

export default Index;
