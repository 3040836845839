import React from "react";

export const useTabTitle = () => {
  const currentTitle = document.title;
  const changeTitle = (title: string) => (document.title = title);
  return { currentTitle, changeTitle };
};

export const useFetchStatus = (defaultLoading?: boolean) => {
  const [loading, setLoading] = React.useState(!!defaultLoading);
  const [error, setError] = React.useState<null | any>(null);

  return [loading, setLoading, error, setError];
};
