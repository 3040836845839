import { HStack } from "@chakra-ui/react";
import React from "react";

import Radio from "./Radio";

interface IProps {
  options: any[];
  onChange: (value: any) => void;
  defaultValue?: string;
  isDisabled?: boolean;
}

const Index: React.FC<IProps> = (props) => {
  const { options, onChange, defaultValue, isDisabled } = props;
  const [selectedValue, setSelectedValue] = React.useState(defaultValue || "");

  React.useEffect(() => {
    onChange(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  return (
    <HStack mt="1rem">
      {options.map((option, index) => {
        return (
          <Radio
            value={option.value}
            selectedColor={option.color}
            isDisabled={!!isDisabled}
            isSelected={selectedValue === option.value}
            key={`selectOption_${index}_${option.value}`}
            onClick={setSelectedValue}
          >
            {option.value}
          </Radio>
        );
      })}
    </HStack>
  );
};

export default Index;
