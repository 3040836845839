import axios from "axios";

import config from "../config/env";
import { IRfiResponse, ISubmittal } from "../redux/interfaces";

export const getAllSubmittals = () => axios.get(`${config.API}/submittals`);

export const openSubmittal = (newSubmittal: ISubmittal) =>
  axios.post(`${config.API}/submittal`, newSubmittal);

export const sendNewSubmittal = (message: IRfiResponse) =>
  axios.put(`${config.API}/submittal`, message);
