import React, { useEffect, useState } from "react";
import { Image } from "@chakra-ui/image";
import { useHistory, useParams } from "react-router-dom";
import {
  HStack,
  Box,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Flex,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";

import arupLogo from "./../assets/images/arup_red.svg";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { HiViewGrid } from "react-icons/hi";
import { HamburgerIcon } from "@chakra-ui/icons";
import { getProjectViews } from "../api/getProjectViews";
import { viewsActions } from "../redux/ViewsSlice";

interface IProps {}

const menuActions = [
  {
    route: "",
    label: "Home",
  },
  {
    route: "documents",
    label: "Documents",
  },
  {
    route: "rfi",
    label: "RFI",
  },
  {
    route: "submittals",
    label: "Submittals",
  },
  {
    route: "issues",
    label: "Issues",
  },
];

const Navbar: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { location } = history;

  const [activePath, setActivePath] = useState("");
  const projectInfo = useAppSelector((state) => state.projectInfoStorage);
  const views: string[] = useAppSelector(
    (state) => state.viewsStorage
  ) as string[];
  let { projectName }: { projectName } = useParams();

  history.listen((location) => setActivePath(location.pathname));

  const isActiveRoute = (route: string) => (activePath === route).toString();

  const navigateTo = (path: string) => history.push(path);

  useEffect(() => {
    setActivePath(history.location.pathname);
    const projectReference = location.pathname
      .split("/")
      .filter((x) => x.trim() !== "")
      .shift();
    if (projectReference) {
      getProjectViews(projectReference).then((viewsResponse) =>
        dispatch(viewsActions.set(viewsResponse.data.views))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      bg="white.500"
      height="3rem"
      padding="0.875rem 3rem"
      justifyContent="space-between"
      boxShadow="base"
      position="sticky"
      top="0"
      left="0"
      zIndex="400"
    >
      <Stack direction="row" spacing={[0, 28]}>
        <Stack
          direction="row"
          w={{ base: "9rem", sm: "21rem" }}
          alignItems="center"
        >
          <Text
            display={{ base: "none", sm: "block" }}
            fontFamily="Times New Roman"
            color="primary.main"
            fontSize={{ base: "small", sm: "heading2" }}
          >
            d.Hub field
          </Text>
          {projectName && (
            <Text
              fontFamily="Times New Roman"
              color="black.300"
              fontSize={{ base: "small", sm: "heading2" }}
            >
              {projectInfo.name}
            </Text>
          )}
        </Stack>
        {projectName && (
          <>
            <Box
              display={{
                base: "none",
                sm: "block",
              }}
            >
              <DesktopMenu
                isActiveRoute={isActiveRoute}
                navigateTo={navigateTo}
                projectName={projectName}
                filteredMenuActions={menuActions.filter((it) =>
                  views.includes(it.label)
                )}
              />
            </Box>
          </>
        )}
      </Stack>

      <HStack w="fit-content" spacing="2rem">
        <Box
          display={{
            base: "block",
            sm: "none",
          }}
        >
          <MobileMenu
            isActiveRoute={isActiveRoute}
            navigateTo={navigateTo}
            projectName={projectName}
            filteredMenuActions={menuActions.filter((it) =>
              views.includes(it.label)
            )}
          />
        </Box>
        {projectInfo.projectsList.length > 1 && (
          <Icon
            as={HiViewGrid}
            color="black.800"
            _hover={{
              cursor: "pointer",
              color: "black.400",
            }}
            onClick={(_) => navigateTo(`/`)}
          />
        )}
        <Image src={arupLogo} alt="Arup logo" width="4rem" />
      </HStack>
    </Flex>
  );
};

const MobileMenu = (props: {
  isActiveRoute: any;
  navigateTo: any;
  projectName: string;
  filteredMenuActions: any[];
}) => {
  return (
    <Menu>
      <MenuButton
        aria-label="Options"
        border="0px"
        position="relative"
        width="1.25rem"
        height="1.25rem"
      >
        <Icon
          as={HamburgerIcon}
          color="black.800"
          _hover={{
            cursor: "pointer",
            color: "black.400",
          }}
          height="20px"
          paddingBottom="2px"
        />
      </MenuButton>
      <MenuList>
        {props.filteredMenuActions &&
          props.filteredMenuActions.map((it, index) => {
            return (
              <MenuItem key={index}>
                <Link
                  variant="navigation"
                  isactive={props.isActiveRoute(
                    `/${props.projectName}/${it.route}`
                  )}
                  onClick={(_) =>
                    props.navigateTo(`/${props.projectName}/${it.route}`)
                  }
                >
                  {it.label}
                </Link>
              </MenuItem>
            );
          })}
      </MenuList>
    </Menu>
  );
};

const DesktopMenu = (props: {
  isActiveRoute: any;
  navigateTo: any;
  projectName: string;
  filteredMenuActions: any[];
}) => {
  return (
    <Stack direction="row" spacing="8" alignItems="center">
      {props.filteredMenuActions &&
        props.filteredMenuActions.map((it, index) => {
          return (
            <Link
              key={index}
              variant="navigation"
              isactive={props.isActiveRoute(
                `/${props.projectName}/${it.route}`
              )}
              onClick={(_) =>
                props.navigateTo(`/${props.projectName}/${it.route}`)
              }
            >
              {it.label}
            </Link>
          );
        })}
    </Stack>
  );
};

export default Navbar;
