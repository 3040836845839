import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IDocument, ICategory, IRawDeliverableResponse } from "./interfaces";

interface ISlice {
  documentsList: IDocument[];
  filteredDocumentsList: IDocument[];
  categoriesList: ICategory[];
  activeDocument: IDocument | null;
}

const sliceInitialState: ISlice = {
  documentsList: [],
  filteredDocumentsList: [],
  categoriesList: [],
  activeDocument: null,
};

const customSlice = createSlice({
  name: "documentRepository",
  initialState: sliceInitialState,
  reducers: {
    insertDocumentsList: (
      state,
      action: PayloadAction<IRawDeliverableResponse[]>
    ) => {
      const convertedDocuments = action.payload.map(convertToLastVersion);
      return {
        ...state,
        documentsList: convertedDocuments,
        filteredDocumentsList: convertedDocuments,
      };
    },
    insertCategoriesList: (state, action: PayloadAction<ICategory[]>) => ({
      ...state,
      categoriesList: action.payload,
    }),
    insertFilteredDocumentsList: (
      state,
      action: PayloadAction<IDocument[]>
    ) => ({ ...state, filteredDocumentsList: action.payload }),
    insertOrUpdateDocument: (state, action: PayloadAction<IDocument>) => {
      const convertedDocument = convertToLastVersion(action.payload);
      let documentsList: IDocument[] = [...state.documentsList];

      if (
        documentsList.findIndex((doc) => doc.id === convertedDocument.id) !== -1
      ) {
        const index = documentsList.findIndex(
          (doc) => doc.id === convertedDocument.id
        );
        documentsList.splice(index, 1, convertedDocument);
      } else documentsList.push(convertedDocument);

      return { ...state, documentsList };
    },
    setActiveDocument: (state, action: PayloadAction<IDocument>) => ({
      ...state,
      activeDocument: action.payload,
    }),
    resetActiveDocument: (state) => ({ ...state, activeDocument: null }),
  },
});

export const convertToLastVersion = (document: IRawDeliverableResponse) => {
  const { owner, dateCreated, version, key } =
    document.versions[document.versions.length - 1];

  const doc: IDocument = {
    ...document,
    key: key!,
    owner,
    dateCreated: dateCreated!,
    version: version!,
  };
  return doc;
};

// const splitCategories = (prefix: string) => {
//   const splittedPrefix = prefix.split("/");
//   return {
//     category: splittedPrefix[0],
//     subcategory: splittedPrefix[1],
//   };
// };

export const actions = customSlice.actions;
export default customSlice.reducer;
