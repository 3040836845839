import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
  baseStyle: {},
  variants: {
    outline: {
      field: {
        borderColor: "black.800",
        bg:"white.500",
        _hover: { borderColor: "black.600" },
        _focus: { borderColor: "black.400" },
      },
    },
  },
};

export default componentOverride;
