import React, { useEffect, useState } from "react";
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Flex,
  Grid,
  GridItem,
  Text,
  Button,
} from "@chakra-ui/react";
import { format } from "date-fns";

import AccordionHeader from "./AccordionHeader";
import IssueImage from "./IssueImage";

import { useAppSelector } from "../../../redux/hooks";
import { IIssue } from "../../../redux/interfaces";
import IssuePriorityIndicator from "../../../shared/IssuePrioritytIndicator";
import IssueStatusIndicator from "../../../shared/IssueStatusIndicator";
import { motion } from "framer-motion";
import { putIssues, deleteIssues } from "../../../api/issues";
import { getSignedUrl } from "../../../api/getSignedUrl";

interface IProps {}

const Index: React.FC<IProps> = (props) => {
  const issues: IIssue[] = useAppSelector(
    (state) => state.issuesStorage.selectedIssueList
  );

  const [urlsState, setUrlsState] = useState<(string | undefined)[]>(
    issues.map((it) => undefined)
  );

  useEffect(() => {
    async function fetchMyAPI() {
      const promises = issues.map((it) => {
        if (it.picture_reference) {
          return getSignedUrl(it.picture_reference);
        } else {
          return undefined;
        }
      });

      const urls: (string | undefined)[] = (
        await Promise.allSettled(promises)
      ).map((it) => {
        if (it.status === "fulfilled" && it.value === undefined) {
          return "/placeholder.png";
        } else if (it.status === "rejected") {
          return "/placeholder-red.png";
        } else {
          return (it.value as unknown as { data: string }).data;
        }
      });
      setUrlsState(urls);
    }

    fetchMyAPI();
  }, [issues]);

  const reducedHeaders = !issues.some((it) => it.subdiscipline || it.subarea);

  return (
    <Box
      height="100%"
      width="100%"
      minWidth="60rem"
      overflowX="auto"
      overflowY="scroll"
      pb={1}
      position="relative"
    >
      <Box position="absolute" top="-100px" width="100%">
        <motion.div animate={{ y: 100 }}>
          <AccordionHeader reducedHeaders={reducedHeaders} />
          <Accordion defaultIndex={[0]} allowMultiple bg="white.500" px="1rem">
            {issues.map((issue, index) => (
              <AccordionItem key={index}>
                <AccordionButton>
                  <Grid
                    w="100%"
                    templateColumns="repeat(11, 1fr)"
                    gap={5}
                    fontSize="heading3"
                  >
                    <GridItem
                      colSpan={3}
                      textAlign="left"
                      fontFamily="Times New Roman"
                      alignSelf="center"
                    >
                      {issue.name}
                    </GridItem>
                    <GridItem
                      colSpan={1}
                      alignSelf="center"
                      color="black.800"
                      textAlign="left"
                    >
                      {issue.discipline}
                    </GridItem>
                    <GridItem
                      colSpan={1}
                      alignSelf="center"
                      color="black.800"
                      textAlign="left"
                    >
                      {issue.subdiscipline}
                    </GridItem>
                    <GridItem
                      colSpan={1}
                      alignSelf="center"
                      color="black.800"
                      textAlign="left"
                    >
                      {issue.floor}
                    </GridItem>
                    <GridItem
                      colSpan={1}
                      alignSelf="center"
                      color="black.800"
                      textAlign="left"
                    >
                      {issue.area}
                    </GridItem>
                    <GridItem
                      colSpan={1}
                      alignSelf="center"
                      color="black.800"
                      textAlign="left"
                    >
                      {issue.subarea}
                    </GridItem>
                    <GridItem>
                      <Flex
                        h="100%"
                        direction="column"
                        justify="center"
                        alignItems="center"
                      >
                        <IssuePriorityIndicator priority={issue.priority} />
                      </Flex>
                    </GridItem>
                    <GridItem>
                      <Flex
                        h="100%"
                        direction="column"
                        justify="center"
                        alignItems="center"
                      >
                        <IssueStatusIndicator status={issue.status} />
                      </Flex>
                    </GridItem>
                    <GridItem textAlign="right">
                      <AccordionIcon color="black.300" />
                    </GridItem>
                  </Grid>
                </AccordionButton>
                <AccordionPanel
                  className="detail"
                  borderTop="0.5px solid"
                  borderColor="white.100"
                  py={4}
                >
                  <Grid w="100%" templateColumns="repeat(11, 1fr)" gap={5}>
                    <GridItem colSpan={5} textAlign="left">
                      <Flex fontSize="xs">
                        <Text color="black.600" fontWeight="bold" mr={1}>
                          Issue publication date:
                        </Text>
                        <Text color="black.600">
                          {format(new Date(issue.date!), "dd/MM/yyyy")}
                        </Text>
                      </Flex>
                      {issue.solved_date && (
                        <Flex fontSize="xs">
                          <Text color="black.600" fontWeight="bold" mr={1}>
                            Issue solved at:
                          </Text>
                          <Text color="black.600">
                            {format(new Date(issue.solved_date), "dd/MM/yyyy")}
                          </Text>
                        </Flex>
                      )}

                      <Text mt="0.75rem" fontSize="small" color="black.600">
                        {issue.description}
                      </Text>
                    </GridItem>
                    <GridItem colSpan={2} textAlign="left">
                      <IssueImage url={urlsState[index]} />
                    </GridItem>
                    <GridItem colSpan={2} textAlign="left"></GridItem>
                    <GridItem colSpan={1} textAlign="left">
                      <Button
                        fontSize="small"
                        onClick={() => {
                          putIssues(issue.id, !issue.status)
                            .then((foo) => window.location.reload())
                            .catch((err) =>
                              alert("Failed to modify the issue.")
                            );
                        }}
                      >
                        {issue.status ? "Mark as Open" : "Mark as Solved"}
                      </Button>
                    </GridItem>
                    <GridItem colSpan={1} textAlign="left">
                      <Button
                        fontSize="small"
                        onClick={() => {
                          deleteIssues(issue.id)
                            .then((foo) => window.location.reload())
                            .catch((err) =>
                              alert("Failed to modify the issue.")
                            );
                        }}
                      >
                        Delete
                      </Button>
                    </GridItem>
                  </Grid>
                </AccordionPanel>
              </AccordionItem>
            ))}
          </Accordion>
        </motion.div>
      </Box>
    </Box>
  );
};

export default Index;
