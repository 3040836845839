import React from "react";

import { Flex, Text, Spacer, Box, Button } from "@chakra-ui/react";
import { useHistory, useParams } from "react-router-dom";

interface IProps {
  title: string;
  route: string;
}

const ChartHeader: React.FC<IProps> = (props) => {
  const history = useHistory();
  const navigateTo = (path: string) => history.push(path);
  const { projectName }: { projectName } = useParams();

  return (
    <Flex align="center" my={1}>
      <Box>
        <Text fontSize="base" fontFamily="Times New Roman" color="primary.main">
          {props.title}
        </Text>
      </Box>
      <Spacer />
      <Box>
        <Button
          variant="solidPrimary"
          onClick={(_) => navigateTo(`/${projectName}/${props.route}`)}
        >
          See
        </Button>
      </Box>
    </Flex>
  );
};

export default ChartHeader;
