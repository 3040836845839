import { Box, Checkbox, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";

import PercentageBar from "./PercentageBar";

export interface IFilter {
  label: string;
  quantity: number;
  isActive: boolean;
  key: any;
  color?: string;
}

interface IProps {
  title: string;
  filterKey: string;
  filterOptions: IFilter[];
  filterSetter: (filter: IFilter[]) => void;
}

const FilterGroup: React.FC<IProps> = (props) => {
  const { title, filterOptions, filterSetter } = props;
  const [toggleAll, setToggleAll] = React.useState(true);

  const changeFilterOptions = (event, label: string) => {
    const foundIndex = filterOptions.findIndex(
      (filter) => filter.label === label
    );
    if (foundIndex > -1) {
      const newFilters = filterOptions.map((filter, index: number) => ({
        ...filter,
        ...(index === foundIndex && { isActive: event.target.checked }),
      }));
      filterSetter(newFilters);
      setToggleAll(newFilters.some((filter) => filter.isActive));
    }
  };

  const onToggleAll = () => {
    const nextToggleAll = !toggleAll;
    const newFilters = filterOptions.map((filter, index: number) => ({
      ...filter,
      isActive: nextToggleAll,
    }));

    filterSetter(newFilters);
    setToggleAll(!toggleAll);
  };

  return (
    <Box>
      <Divider margin="0.75rem 0" w="100%" />
      <Text color="black.200" fontSize="base" mb="1rem" fontWeight="bold">
        {title}
      </Text>
      <Checkbox
        spacing="0.75rem"
        w="50%"
        onChange={onToggleAll}
        defaultIsChecked
        variant="primary"
        mb="1rem"
      >
        {toggleAll ? "Deselect all" : "Select all"}
      </Checkbox>
      {!!filterOptions.length &&
        filterOptions.map((filter, index) => (
          <Flex
            justifyContent="space-between"
            alignItems="center"
            key={`rfiFilter_${index}_${filter.label}`}
            mb="0.5rem"
          >
            <Checkbox
              spacing="0.75rem"
              w="60%"
              isChecked={filter.isActive}
              onChange={(event) => changeFilterOptions(event, filter.label)}
            >
              {filter.label}
            </Checkbox>
            <Box w="40%">
              <PercentageBar
                percentage={filter.quantity}
                label={`${filter.quantity}`}
                {...(filter.color && { color: filter.color })}
              />
            </Box>
          </Flex>
        ))}
      {/* <Button variant="outlinePrimary" onClick={onToggleAll} mt=".75rem">
        {toggleAll ? "Deselect all" : "Select all"}
      </Button> */}
    </Box>
  );
};

export default FilterGroup;
