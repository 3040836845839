import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type IView = "Home" | "Documents" | "Issues" | "Submittals" | "RFI";

const sliceInitialState: IView[] = [
  "Home",
  "Documents",
  "Issues",
  "Submittals",
  "RFI",
];

const customSlice = createSlice({
  name: "views",
  initialState: sliceInitialState,
  reducers: {
    set: (state, action: PayloadAction<IView[]>) => {
      return action.payload;
    },
  },
});

export const viewsActions = customSlice.actions;
export default customSlice.reducer;
