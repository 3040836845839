import React from "react";
import { Icon } from "@chakra-ui/react";
import { FiAlertTriangle } from "react-icons/fi";

interface IProps {
  critical: boolean;
}

const RfiCriticalndicator: React.FC<IProps> = (props) => {
  return props.critical ? (
    <Icon as={FiAlertTriangle} color="primary.main" fontSize="1.5rem" />
  ) : (
    <></>
  );
};

export default RfiCriticalndicator;
