import React from "react";
import { Flex, HStack, IconButton, Text, VStack } from "@chakra-ui/react";
import { FiX } from "react-icons/fi";

import RfiCriticalndicator from "../RfiCriticalndicator";

interface IProps {
  selectedConversation: any; // selectedRfi
  indicator: any;
  onClose: () => void;
}

const Index: React.FC<IProps> = (props) => {
  const { selectedConversation, onClose, indicator } = props;

  const containerRef = React.createRef<any>();

  React.useEffect(() => {
    if (containerRef) {
      (containerRef.current as any).scrollTop = (
        containerRef.current as any
      ).scrollHeight;
    }
  }, [containerRef]);

  return (
    <VStack
      maxH="100%"
      boxShadow="lg"
      bg="white.500"
      p="1.5rem"
      borderRadius="sm"
      spacing="0.4rem"
      alignItems="flex-start"
      overflow="scroll"
      ref={containerRef}
    >
      <Flex w="100%" alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Text fontSize="medium" color="primary.main">
            {selectedConversation?.id}
          </Text>
          <Text fontSize="medium" color="primary.main" mx={1}>
            -
          </Text>
          <Text fontSize="medium" color="primary.main">
            {selectedConversation?.discipline}
          </Text>
        </Flex>

        <IconButton
          aria-label="Close rfi"
          icon={<FiX />}
          variant="noBackground"
          color="black.800"
          p="0"
          m="0"
          fontSize="base"
          onClick={onClose}
          size="xs"
        />
      </Flex>
      <Text fontSize="base" fontFamily="Times New Roman">
        {selectedConversation.subject}
      </Text>
      <Text fontSize="small" color="black.600">
        Sent to:&nbsp;
        {Array.isArray(selectedConversation.departments)
          ? selectedConversation.departments.join(", ")
          : selectedConversation.departments}
      </Text>
      <HStack alignItems="center" spacing="1rem" p="0.5rem 0">
        {indicator}
        <RfiCriticalndicator critical={selectedConversation.critical} />
      </HStack>
      {props.children}
    </VStack>
  );
};

export default Index;
