import React from "react";
import { Divider, HStack, Link, List, ListItem, Text } from "@chakra-ui/react";
import { useHistory } from "react-router";

import { convertDateToReadableString } from "../../../config/utils";
import { IAttachedFile, IMessage } from "../../../redux/interfaces";
import { getSignedUrl } from "../../../api/getSignedUrl";

interface IProps {
  message: IMessage;
  type: "Request" | "Answer" | "Clarification" | "Clarification Answer";
  index: number;
  conversation: any;
}

const MessageView: React.FC<IProps> = (props) => {
  const { message, type, index, conversation } = props;
  console.log(message);
  const history = useHistory();

  const triggerDownloadFile = async (file: IAttachedFile) => {
    try {
      if (file.type === "newUpload" || file.type === "selection") {
        const response = await getSignedUrl(file.id!);
        window.open(response.data, "_black");
      } else if (file.type === "innerLink") {
        history.push(file.id!);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const isLast = () => conversation.conversation.length - 1 === index;
  const isConversationClosed = () => conversation.closed;

  const renderBody = () => {
    if (!isLast() || (isLast() && !isConversationClosed())) {
      return (
        <>
          <Text fontSize="small">{message.message}</Text>
          <List>
            {message.files.map((file) => (
              <ListItem
                key={Math.random()}
                onClick={(_) => triggerDownloadFile(file)}
              >
                <Link fontSize="small" color="primary.main">
                  {file.name}
                </Link>
              </ListItem>
            ))}
          </List>
        </>
      );
    }

    return <></>;
  };

  return (
    <>
      <Divider />
      <Text fontSize="small">
        {isConversationClosed() && isLast() ? "Accepted" : type}
      </Text>
      <HStack w="100%" justifyContent="space-between">
        <Text fontSize="small" color="black.600">
          {message.posted_by}
        </Text>
        <Text fontSize="small" color="black.600">
          {convertDateToReadableString(message.date!, "full")}
        </Text>
      </HStack>
      {renderBody()}
    </>
  );
};

export default MessageView;
