import { Button } from "@chakra-ui/button";
import { Box, Flex } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "./../../redux/hooks";

import LeftSidebar from "./LeftSidebar";
import UploadDocumentModal from "./UploadDocumentModal";
import CustomTable from "./Table/Table";
import { actions } from "../../redux/DocumentRepositorySlice";
import { getAllCategories, getAllDeliverables } from "./../../api/documents";
import PageTitle from "./../PageTitle";

interface IProps {}

const Documents: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();

  const [isUploadDocumentModalOpen, setIsUploadDocumentModalOpen] =
    useState(false);

  useEffect(() => {
    try {
      getAllDeliverables().then(({ data }) =>
        dispatch(actions.insertDocumentsList(data))
      );
      getAllCategories().then(({ data }) =>
        dispatch(actions.insertCategoriesList(data))
      );
    } catch (err) {
      console.log(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="top" mb="1.25rem">
        <PageTitle title="Document Storage" />
        <Button
          variant="solidPrimary"
          onClick={() => {
            dispatch(actions.resetActiveDocument());
            setIsUploadDocumentModalOpen(true);
          }}
        >
          Upload new file
        </Button>
      </Flex>
      <Flex alignItems="top">
        <LeftSidebar />
        <Box flex="1">
          <CustomTable
            setIsUploadDocumentModalOpen={setIsUploadDocumentModalOpen}
          />
        </Box>
      </Flex>
      <UploadDocumentModal
        isUploadDocumentModalOpen={isUploadDocumentModalOpen}
        setIsUploadDocumentModalOpen={setIsUploadDocumentModalOpen}
      />
    </Box>
  );
};

export default Documents;
