import React from "react";
import { Table, Thead, Tbody, Tr, Th, Td, chakra } from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { useTable, useSortBy, Column } from "react-table";

export interface IColumnProperties {
  props: any;
  customRenderer?: (cell: any) => any;
}

interface IProps {
  data: any[];
  columns: Column<any>[];
  customColumns?: IColumnProperties[];
  selectedRow?: any;
  setSelectedRow?: (row: any) => void;
}

const CustomTable: React.FC<IProps> = (props) => {
  const { data, columns, customColumns, selectedRow, setSelectedRow } = props;

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <>
      <Table
        {...getTableProps()}
        w="100%"
        __css={{
          borderCollapse: "separate",
          borderSpacing: "0 1rem",
        }}
      >
        <Thead>
          {headerGroups.map((headerGroup: any) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  isNumeric={column.isNumeric}
                  fontSize="xs"
                  color="black.600"
                  border="none"
                >
                  {column.render("Header")}
                  <chakra.span pl="4">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <TriangleDownIcon
                          aria-label="sorted descending"
                          color="primary.main"
                        />
                      ) : (
                        <TriangleUpIcon
                          aria-label="sorted ascending"
                          color="primary.main"
                        />
                      )
                    ) : (
                      <>
                        <TriangleUpIcon
                          aria-label="sorted ascending"
                          color="black.900"
                        />
                        <TriangleDownIcon
                          aria-label="sorted descending"
                          color="black.900"
                        />
                      </>
                    )}
                  </chakra.span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} bg="white.500" borderRadius="md">
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <Tr
                {...row.getRowProps()}
                position="relative"
                borderRadius="md"
                transition="0.3s all"
                _hover={{
                  cursor: "pointer",
                  boxShadow: "-3px 0px 0px 0px #E61E28CC",
                }}
                {...(selectedRow &&
                  selectedRow.id === row.id && {
                    boxShadow: "-3px 0px 0px 0px #E61E28",
                  })}
                onClick={(_) => !!setSelectedRow && setSelectedRow(row)}
              >
                {columns.map((_, index) => (
                  <Td
                    {...row.cells[index].getCellProps()}
                    isNumeric={row.cells[index].column.isNumeric}
                    {...(customColumns && { ...customColumns[index].props })}
                    key={`column_${index}`}
                  >
                    {(customColumns &&
                      customColumns[index].customRenderer &&
                      customColumns[index].customRenderer!(row.cells[index])) ||
                      row.cells[index].render("Cell")}
                  </Td>
                ))}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </>
  );
};

export default CustomTable;
