import React from "react";

import { Box, Flex, Divider, Spinner } from "@chakra-ui/react";
import { getMonth, getYear } from "date-fns";

import { getAllRfi } from "../../api/rfi";
import { getAllSubmittals } from "../../api/submittals";
import { getIssues } from "../../api/issues";
import { actions as rfiActions } from "../../redux/RfiSlice";
import { actions as submittalActions } from "../../redux/SubmittalSlice";
import { actions as issueActions } from "../../redux/IssuesSlice";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useFetchStatus } from "../../hooks";
import BarChart from "./BarChart";
import ChartHeader from "./ChartHeader";
import LineChart from "./LineChart";
import theme from "../../config/theme";

interface IProps {}

const getMonthName = (month: number) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  return months[month];
};

const Home: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useFetchStatus(true);

  const getData = async () => {
    try {
      setLoading(true);
      const rfiResponse = await getAllRfi();
      const submittalResponse = await getAllSubmittals();
      const issuesResponse = await getIssues();
      dispatch(rfiActions.insertRfiList(rfiResponse.data));
      dispatch(submittalActions.insertList(submittalResponse.data));
      dispatch(issueActions.insertList(issuesResponse.data));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const rfis = useAppSelector((state) => state.rfiStorage.rfiList);
  
  const views: string[] = useAppSelector(
    (state) => state.viewsStorage
  ) as string[];

  const submittals = useAppSelector(
    (state) => state.submittalStorage.submittalList
  );
  const projectPhoto = useAppSelector(
    (state) => state.projectInfoStorage.photo_reference
  );
  const issues = useAppSelector((state) => state.issuesStorage.issueList);

  const rfiData = {
    labels: ["Request to answer", "Answers to accept", "Closed requests"],
    datasets: [
      {
        data: [
          rfis.filter((rfi) => rfi.status === "pendingResponse").length,
          rfis.filter((rfi) => rfi.status === "pendingApproval").length,
          rfis.filter((rfi) => rfi.status === "closed").length,
        ],
        backgroundColor: [
          theme.colors.white[200],
          theme.colors.white[800],
          theme.colors.black[300],
        ],
        borderRadius: 4,
        barThickness: 16,
      },
    ],
  };
  const submittalData = {
    labels: [
      "Submittals to approve",
      "A - Approved",
      "B - Approved with comments",
      "C - Rejected",
    ],
    datasets: [
      {
        data: [
          submittals.filter((sub) => !sub.status).length,
          submittals.filter((sub) => sub.status === "A").length,
          submittals.filter((sub) => sub.status === "B").length,
          submittals.filter((sub) => sub.status === "C").length,
        ],
        backgroundColor: [
          theme.colors.white[200],
          theme.colors.green["light"],
          theme.colors.yellow["dark"],
          theme.colors.primary["main"],
        ],
        borderRadius: 4,
        barThickness: 16,
      },
    ],
  };

  const monthLabels: { date: Date; label: string }[] = issues
    .map((i) => ({
      date: new Date(Date.parse(i.date)),
      label:
        getMonthName(getMonth(Date.parse(i.date))) +
        " " +
        getYear(Date.parse(i.date)),
    }))
    .sort((a, b) => a.date.getTime() - b.date.getTime());

  const openSolvedData: {
    date: Date;
    label: string;
    open: number;
    solved: number;
  }[] = monthLabels.map((it, index) => {
    return {
      ...it,
      open: issues.filter(
        (issue) => new Date(issue.date).getTime() <= new Date(it.date).getTime()
      ).length,
      solved: issues
        .filter((issue) => issue.solved_date)
        .filter(
          (issue) =>
            new Date(issue.solved_date!).getTime() <=
            new Date(it.date).getTime()
        ).length,
    };
  });

  const uniqueOpenSolvedData = Array.from(
    new Set(openSolvedData.map((it) => JSON.stringify(it)))
  ).map((it) => JSON.parse(it));

  React.useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Box
        margin={{ base: "-2rem -3rem", sm: "-2rem -3rem" }}
        bgImage={projectPhoto}
        bgSize="cover"
        backgroundPosition="center"
        width="100vw"
        h="calc(100vh - 3rem)"
        overflowY="hidden"
        overflowX="hidden"
      >
        {loading && (
          <Spinner
            color="primary.main"
            position="absolute"
            left="50%"
            top="50%"
            transform="translate(-50%)"
          />
        )}
        {!loading && (
          <Flex
            flexDir="column"
            justifyContent="space-around"
            padding="1.813rem 2rem"
            pos="absolute"
            margin="2rem 3rem"
            h="fit-content"
            w={{ base: "22.5rem", sm: "28.5rem" }}
            maxHeight="85vh"
            bgColor="white"
            boxShadow="0.25rem 0.25rem 0.375rem #00000029"
            overflow="auto"
          >
            <Box>
              {views && views.includes("RFI") && (
                <>
                  <ChartHeader title={"RFI status"} route={"rfi"} />
                  <BarChart data={rfiData} />
                  <Divider mt={2} mb={2} />
                </>
              )}
              {views && views.includes("Submittals") && (
                <>
                  <ChartHeader
                    title={"Submittals status"}
                    route={"submittals"}
                  />
                  <BarChart data={submittalData} />
                  <Divider mt={2} mb={2} />
                </>
              )}
              {views && views.includes("Issues") && (
                <>
                  <ChartHeader title={"Issues tracker"} route={"issues"} />
                  <LineChart issueData={uniqueOpenSolvedData} />
                </>
              )}
            </Box>
          </Flex>
        )}
      </Box>
    </>
  );
};

export default Home;
