import {
  Box,
  Flex,
  IconButton,
  List,
  ListIcon,
  ListItem,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { FiCheck, FiTrash2, FiUploadCloud } from "react-icons/fi";

import { IAttachedFile } from "../../redux/interfaces";

interface IProps {
  attachedFiles: IAttachedFile[];
  setAttachedFiles: (attachedFiles: IAttachedFile[]) => void;
}

const AttachedFilesList: React.FC<IProps> = (props) => {
  const { attachedFiles, setAttachedFiles } = props;

  const dettachFile = (file: IAttachedFile) => {
    const editedAttachedFiles = [...attachedFiles];
    const index = editedAttachedFiles.findIndex((aF) => aF === file);
    editedAttachedFiles.splice(index, 1);
    setAttachedFiles(editedAttachedFiles);
  };

  return (
    <List>
      {attachedFiles.map((file, index) => (
        <ListItem p="0.5rem" key={`attachedFile_${index}`}>
          <Flex alignItems="center" justifyContent="space-between">
            <Box>
              <ListIcon as={file.id ? FiCheck : FiUploadCloud} />
              <Text fontSize="small" display="inline-block">
                {file.name}
              </Text>
            </Box>
            <IconButton
              aria-label="De-attach file"
              icon={<FiTrash2 />}
              variant="noBackground"
              onClick={(_) => dettachFile(file)}
              fontSize="small"
            />
          </Flex>
        </ListItem>
      ))}
    </List>
  );
};

export default AttachedFilesList;
