import React from "react";
import { Spinner, Text, Flex, VStack } from "@chakra-ui/react";

interface IProps {}

const Index: React.FC<IProps> = (props) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      position="absolute"
      left="50%"
      top="50%"
      h="100vh"
      transform="translate(-50%)"
    >
      <VStack>
        <Spinner color="primary.main" />
        <Text fontSize="base" color="primary.main">
          Loading content...
        </Text>
      </VStack>
    </Flex>
  );
};

export default Index;
