import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

import Button from "./theme/Button";
import Checkbox from "./theme/Checkbox";
import Link from "./theme/Link";
import Input from "./theme/TextInput";
import Modal from "./theme/Modal";
import FormLabel from "./theme/FormLabel";

const breakpoints = createBreakpoints({
  sm: "1081px",
  md: "1281px",
  lg: "1367px",
  xl: "1537px",
});

const theme = extendTheme({
  colors: {
    primary: {
      main: "#E61E28",
      light: "#E61E28CC",
    },
    yellow: {
      dark: "#E9BF14",
      light: "#FBC700",
    },
    green: {
      dark: "#528B30",
      light: "#54AE4F",
      transparent: "#ddecd6",
    },
    black: {
      100: "#182322",
      200: "#243533",
      300: "#525758",
      400: "#707070",
      500: "#6B7675",
      600: "#95979A",
      800: "#B5B5B8",
      900: "#C2C8C7",
    },
    white: {
      100: "#0000001A",
      200: "#E4E4E5",
      300: "#F5F5F5",
      400: "#F8F8F9",
      500: "#FFFFFF",
    },
  },
  fonts: {
    heading: "Arial",
    body: "Arial",
  },
  fontSizes: {
    heading1: "1.375rem",
    heading2: "1.125rem",
    heading3: "0.9375rem",
    base: "1rem",
    small: "0.75rem",
    xs: "0.625rem",
  },
  components: {
    Button,
    Checkbox,
    Link,
    Input,
    Modal,
    FormLabel,
  },
  styles: {
    global: () => ({
      body: {
        fontFamily: "body",
        lineHeight: "base",
        bg: "white.400",
      },
    }),
  },
  breakpoints,
});

export default theme;
