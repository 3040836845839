import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
  baseStyle: {
    color: "black.600",
    textDecoration: "underline",
  },
  variants: {
    navigation: ({ isactive }) => ({
      fontWeight: "bold",
      color: isactive === "true" ? "primary.main" : "black.200",
      fontSize: "small",
      textDecoration: "none",
      _hover: {
        textDecoration: "none",
        color: "primary.main",
      },
    }),
  },
};

export default componentOverride;
