import React from "react";
import { Divider, HStack, Link, List, ListItem, Text } from "@chakra-ui/react";

import { convertDateToReadableString } from "../../../config/utils";
import { getSignedUrl } from "../../../api/getSignedUrl";
import StatusSelector from "./../StatusSelector/";
import { useHistory } from "react-router";
import { IAttachedFile } from "../../../redux/interfaces";

interface IProps {
  message: any;
  options: any[];
  type: "Request" | "Answer";
  index: number;
  conversation: any;
}

const MessageViewWithStatus: React.FC<IProps> = (props) => {
  const { message, options, type, index, conversation } = props;
  const history = useHistory();

  const triggerDownloadFile = async (file: IAttachedFile) => {
    try {
      if (file.type === "newUpload" || file.type === "selection") {
        const response = await getSignedUrl(file.id!);
        window.open(response.data, "_black");
      } else if (file.type === "innerLink") {
        history.push(file.id!);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const isLast = () => conversation.conversation.length - 1 === index;
  const isConversationClosed = () => conversation.closed;

  const renderBody = () => {
    return (
      <>
        <Text fontSize="small">{message.message}</Text>
        <List>
          {message.files.map((file) => (
            <ListItem
              key={Math.random()}
              onClick={(_) => triggerDownloadFile(file)}
            >
              <Link fontSize="small" color="primary.main">
                {file.name}
              </Link>
            </ListItem>
          ))}
        </List>
      </>
    );
  };

  return (
    <>
      <Divider />
      <Text fontSize="small">
        {isConversationClosed() && isLast() ? "Accepted" : type}
      </Text>
      <HStack w="100%" justifyContent="space-between">
        <Text fontSize="small" color="black.600">
          {message.posted_by}
        </Text>
        <Text fontSize="small" color="black.600">
          {convertDateToReadableString(message.date, "full")}
        </Text>
      </HStack>
      {renderBody()}
      {message.status && (
        <StatusSelector
          options={options}
          defaultValue={message.status}
          onChange={() => {}}
          isDisabled={true}
        />
      )}
    </>
  );
};

export default MessageViewWithStatus;
