import jwt from "jsonwebtoken";

import { IRFI, IVersion } from "../redux/interfaces";
import { getSignedUrl } from "./../api/getSignedUrl";

export const navigateToExternalPage = (link: string) =>
  window.open(link, "_blank");

export const splitPrefix = (prefix: string) => prefix.split(">");

export const getLastDocumentVersion = (versions: IVersion[]) =>
  [...versions].pop()!;

export const getVersionId = (versionId: string) => versionId.split("/")[1];

export const downloadDocument = async (versionKey: string) => {
  const { data } = await getSignedUrl(versionKey);
  navigateToExternalPage(data);
};

export const convertDateToReadableString = (
  date: string | Date,
  type: "onlyDate" | "full"
) => {
  const convertedDate = new Date(date);
  if (type === "full") {
    if (typeof date === "string") return convertedDate.toLocaleString();
    return date.toLocaleString();
  } else if (type === "onlyDate") {
    if (typeof date === "string") return convertedDate.toLocaleDateString();
    return date.toLocaleDateString();
  }
};

export const setRfiStatus = (rfi: IRFI): IRFI => {
  if (rfi.closed) return { ...rfi, status: "closed" };
  else if (rfi.conversation.length % 2 === 0)
    return { ...rfi, status: "pendingApproval" };
  else if (rfi.conversation.length % 2 !== 0)
    return { ...rfi, status: "pendingResponse" };
  return rfi;
};

export const setMessageType = (index: number, closed?: boolean) =>
  index % 2 === 0 ? "Request" : "Answer";

export const decodeToken = (token: string) => jwt.decode(token);

export const isTokenExpired = (token: string) => {
  const payload = decodeToken(token)!;
  return payload["exp"] < Math.trunc(new Date().getTime() / 1000);
};
