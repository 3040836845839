import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  FormControl,
  Select,
  FormErrorMessage,
  FormLabel,
  HStack,
  Button,
  Input,
  Textarea,
  Checkbox,
  Divider,
  useToast,
} from "@chakra-ui/react";
import * as Yup from "yup";
import { Form, Formik, Field } from "formik";
import ReactSelect from "react-select";

import { useAppSelector } from "../../../redux/hooks";
import { IAttachedFile } from "../../../redux/interfaces";
import AttachFiles, { IAllowedAttachment } from "../../../shared/AttachFiles";
import AttachedFilesList from "../../../shared/AttachFiles/AttachedFilesList";

interface IFormValues {
  discipline: string;
  subject: string;
  question: string;
  sendTo: string[];
  isCritical: boolean;
}

const initialFormValues: IFormValues = {
  discipline: "",
  subject: "",
  question: "",
  sendTo: [],
  isCritical: false,
};

const FormSchema = Yup.object().shape({
  discipline: Yup.string().required("Discipline is required"),
  subject: Yup.string().required("Subject is required"),
  question: Yup.string().required("Question is required"),
  sendTo: Yup.array().required("At least one recipient is required"),
  isCritical: Yup.boolean().notRequired(),
});

interface IProps {
  title: string;
  isAddNewRequestOpen: boolean;
  setIsAddNewRequest: (flag: boolean) => void;
  submit: (
    values: any,
    filesToUpload: IAttachedFile[],
    attachedFiles: IAttachedFile[]
  ) => void;
  sendToList: any[];
  isAttachmentsAllowed?: boolean;
  allowedAttachments?: IAllowedAttachment[];
}

const AddNewRequest: React.FC<IProps> = (props) => {
  const {
    title,
    isAddNewRequestOpen,
    setIsAddNewRequest,
    submit,
    sendToList,
    isAttachmentsAllowed,
    allowedAttachments,
  } = props;
  if (isAttachmentsAllowed && allowedAttachments === undefined) {
    throw new Error(
      "allowedAttachments required if attachmentsAllowed is true"
    );
  }
  const [attachedFiles, setAttachedFiles] = React.useState<IAttachedFile[]>([]);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [submittalError, setSubmittalError] = React.useState<string>("");
  const [sendToValues, setSendToValues] = React.useState<any>([]);

  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const disciplinesList = useAppSelector(
    (state) => state.disciplines.disciplines
  );

  const onSubmit = async (values: IFormValues, _: any) => {
    setIsSubmitting(true);
    setSubmittalError("");
    if (attachedFiles.filter((af) => af.file).length === 0) {
      setSubmittalError("A submittal form must be uploaded");
      setIsSubmitting(false);
      return;
    }
    try {
      await submit(
        values,
        attachedFiles.filter((af) => af.file),
        attachedFiles.filter((af) => !af.file)
      );
      onClose();
    } catch (err) {
      toast({
        title: "Not saved " + title,
        description:
          err.message || "An error ocurred while trying to open a new RFI.",
      });
      console.log(err);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onChange = (form, value, { action, removedValue }) => {
    switch (action) {
      case "remove-value":
      case "pop-value":
        if (removedValue.isFixed) {
          return;
        }
        break;
      case "clear":
        value = [];
        break;
    }
    form.setValues({ ...form.values, sendTo: value });
    setSendToValues(value);
  };

  React.useEffect(() => {
    if (isAddNewRequestOpen) {
      onOpen();
      setSendToValues([]);
    }
    !isAddNewRequestOpen && onClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAddNewRequestOpen]);

  React.useEffect(() => {
    setIsAddNewRequest(isOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      isCentered
      variant="customModal"
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>New {title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik
            initialValues={initialFormValues}
            validationSchema={FormSchema}
            onSubmit={onSubmit}
          >
            {(formProps) => (
              <Form>
                <Field name="discipline">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={
                        form.errors.discipline && form.touched.discipline
                      }
                      mb="1rem"
                    >
                      <FormLabel htmlFor="discipline">Discipline</FormLabel>
                      <Select {...field} id="discipline">
                        <option value="" disabled={true}>
                          Select discipline
                        </option>
                        {disciplinesList.map((discipline) => (
                          <option
                            value={discipline}
                            key={`discipline_${discipline}`}
                          >
                            {discipline}
                          </option>
                        ))}
                      </Select>
                      <FormErrorMessage>
                        {form.errors.discipline}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="subject">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={form.errors.subject && form.touched.subject}
                      mb="1rem"
                    >
                      <FormLabel htmlFor="subject">Subject</FormLabel>
                      <Input {...field} id="subject" />
                      <FormErrorMessage>{form.errors.subject}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="question">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={form.errors.question && form.touched.question}
                      mb="1rem"
                    >
                      <FormLabel htmlFor="question">Question</FormLabel>
                      <Textarea {...field} id="question" fontSize="small" />
                      <FormErrorMessage>
                        {form.errors.question}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="sendTo">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={form.errors.sendTo && form.touched.sendTo}
                      mb="1rem"
                    >
                      <FormLabel htmlFor="sendTo">Send to:</FormLabel>
                      <ReactSelect
                        {...field}
                        id="sendTo"
                        value={sendToValues}
                        isMulti
                        closeMenuOnSelect={false}
                        name="sendTo"
                        onChange={(value, { action, removedValue }) =>
                          onChange(form, value, { action, removedValue })
                        }
                        options={sendToList}
                        onBlur={(_) =>
                          form.setTouched({
                            ...form.touched,
                            [field.name]: true,
                          })
                        }
                      />
                      <FormErrorMessage>{form.errors.sendTo}</FormErrorMessage>
                      <FormErrorMessage>{form.errors.sendTo}</FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="critical">
                  {({ field, form }: any) => (
                    <FormControl
                      isInvalid={form.errors.critical && form.touched.critical}
                      mb="1rem"
                    >
                      <Checkbox {...field} id="critical" variant="primary">
                        Critical {title}
                      </Checkbox>
                      <FormErrorMessage>
                        {form.errors.critical}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Divider mb="1rem" />
                {isAttachmentsAllowed && (
                  <FormControl isInvalid={!!submittalError} mb="1rem">
                    <AttachedFilesList
                      attachedFiles={attachedFiles}
                      setAttachedFiles={setAttachedFiles}
                    />
                    <AttachFiles
                      attachedFiles={attachedFiles}
                      setAttachedFiles={setAttachedFiles}
                      allowedAttachments={allowedAttachments!}
                    />
                    <FormErrorMessage>{submittalError}</FormErrorMessage>
                  </FormControl>
                )}
                <Divider m="1rem 0" />
                <HStack w="100%" justifyContent="flex-end">
                  <Button
                    variant="solidPrimary"
                    type="submit"
                    isDisabled={!formProps.isValid || !formProps.dirty}
                    isLoading={isSubmitting}
                    loadingText="Sending data..."
                  >
                    Post request
                  </Button>
                </HStack>
              </Form>
            )}
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AddNewRequest;
