import React, { useEffect } from "react";
import { Box, Button, Flex, Spinner } from "@chakra-ui/react";

import PageTitle from "../PageTitle";
import Accordion from "./Accordion";
import Filters from "./Filters";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { getAllDisciplines } from "../../api/disciplines";
import { getIssues } from "../../api/issues";
import { actions as disciplineActions } from "../../redux/DisciplineSlice";
import { actions as issueActions } from "../../redux/IssuesSlice";
import { useFetchStatus } from "../../hooks";

interface IProps {}

const Index: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();

  const kobo_link = useAppSelector(
    (state) => state.projectInfoStorage.kobo_link
  );

  const [loading, setLoading] = useFetchStatus(true);

  const getData = async () => {
    try {
      setLoading(true);
      const disciplinesResponse = await getAllDisciplines();
      const issuesResponse = await getIssues();
      dispatch(
        disciplineActions.insertDisciplines(
          disciplinesResponse.data.disciplines
        )
      );
      dispatch(issueActions.insertList(issuesResponse.data));
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex flexDirection="column" h="100%">
      <Flex justifyContent="space-between" alignItems="top" mb="1.25rem">
        <PageTitle title="Issues" />
        <Box>
          <Button
            as="a"
            variant="outlinePrimary"
            href={kobo_link}
            target="_blank"
            rel="noreferrer noopener"
          >
            Go to Kobo Form
          </Button>
        </Box>
      </Flex>

      <Flex alignItems="top" flex="1" maxH="95%">
        <Filters />
        <Box flex="1.25">
          {loading && (
            <Spinner
              color="primary.main"
              position="absolute"
              left="50%"
              top="50%"
              transform="translate(-50%)"
            />
          )}
          {!loading && <Accordion />}
        </Box>
      </Flex>
    </Flex>
  );
};

export default Index;
