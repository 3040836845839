import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { ISubmittal } from "./interfaces";

interface ISlice {
  submittalList: ISubmittal[];
  selectedSubmittalList: ISubmittal[];
  selectedSubmittal: ISubmittal | null;
}

const sliceInitialState: ISlice = {
  submittalList: [],
  selectedSubmittalList: [],
  selectedSubmittal: null,
};

const customSlice = createSlice({
  name: "submittal",
  initialState: sliceInitialState,
  reducers: {
    insertList: (state, action: PayloadAction<ISubmittal[]>) => ({
      ...state,
      submittalList: applyModifiers(action.payload),
    }),
    insertNew: (state, action: PayloadAction<ISubmittal>) => ({
      ...state,
      submittalList: applyModifiers([action.payload, ...state.submittalList]),
    }),
    resetList: (state) => ({ ...state, submittalList: [] }),
    insertSelected: (state, action: PayloadAction<ISubmittal>) => ({
      ...state,
      selectedSubmittal: action.payload,
    }),
    resetSelected: (state) => ({
      ...state,
      selectedSubmittal: null,
    }),
    insertSelectedList: (state, action: PayloadAction<ISubmittal[]>) => ({
      ...state,
      selectedSubmittalList: action.payload,
    }),
  },
});

const applyModifiers = (list: ISubmittal[]) =>
  list.sort(orderByCreationDate).map(assignSubmittalStatus);

const assignSubmittalStatus = (submittal: ISubmittal) => ({
  ...submittal,
  status: submittal.conversation[submittal.conversation.length - 1].status,
});

const orderByCreationDate = (a: ISubmittal, b: ISubmittal) => {
  if (!a.date || !b.date || a.date === b.date) return 0;

  return a.date > b.date ? 1 : -1;
};

export const actions = customSlice.actions;
export default customSlice.reducer;
