import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IUserFromAWS } from "./interfaces";

interface IUser {
  email: string;
  name: string;
}
interface ISlice {
  currentUser: IUser;
  usersList: IUserFromAWS[];
}

const sliceInitialState: ISlice = {
  currentUser: { email: "", name: "" },
  usersList: [],
};

const customSlice = createSlice({
  name: "user",
  initialState: sliceInitialState,
  reducers: {
    login: (state, action: PayloadAction<IUser>) => ({
      ...state,
      currentUser: action.payload,
    }),
    setUsersList: (state, action: PayloadAction<any[]>) => ({
      ...state,
      usersList: action.payload,
    }),
  },
});

export const actions = customSlice.actions;
export default customSlice.reducer;
