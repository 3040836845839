import { Box, Text } from "@chakra-ui/react";
import React from "react";

import FilterGroup from "./FilterGroup";

export interface IFilterGroup {
  filterKey: string;
  filterOptions: any[];
  filterSetter: (newValue: any) => void;
  title: string;
}

interface IProps {
  filterGroups: IFilterGroup[];
}

const FilterPane: React.FC<IProps> = (props) => {
  const { filterGroups } = props;
  return (
    <Box maxH="95%" minWidth="18rem" maxWidth="20rem" mr={"2rem"}>
      <Text fontSize="xs" color="black.600" pb={1}>
        Filters
      </Text>
      <Box h="100%" overflowY="scroll">
        {filterGroups.map((filterGroup, index) => (
          <FilterGroup
            key={`filtergroup_${index}`}
            filterKey={filterGroup.filterKey}
            filterOptions={filterGroup.filterOptions}
            filterSetter={filterGroup.filterSetter}
            title={filterGroup.title}
          />
        ))}
        {/* <FilterGroup
        filterKey="registrationDate"
        filterOptions={dateFilter}
        filterSetter={setDateFilter}
        title="Registration date"
      /> */}
      </Box>
    </Box>
  );
};

export default FilterPane;
