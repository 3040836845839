import React from "react";
import { useHistory } from "react-router";
import jwt from "jsonwebtoken";

import config from "../config/env";
import { useAppDispatch } from "../redux/hooks";
import { actions } from "../redux/UserSlice";

interface IProps {}

const Auth: React.FC<IProps> = (props) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const search = history.location.search;
  const idToken = new URLSearchParams(search).get("idToken");
  const recievedRefreshToken = new URLSearchParams(search).get("refreshToken");

  if (idToken) localStorage.setItem(config.TOKEN_STORAGE, idToken);
  if (recievedRefreshToken)
    localStorage.setItem(config.REFRESH_TOKEN_STORAGE, recievedRefreshToken);

  const tokenLocalStorage = localStorage.getItem(config.TOKEN_STORAGE);
  const refreshTokenLocalStorage = localStorage.getItem(
    config.REFRESH_TOKEN_STORAGE
  );

  if (!tokenLocalStorage || !refreshTokenLocalStorage)
    window.location.replace(
      `${config.LOGIN_REDIRECT}?nextUrl=${window.origin}&poolid=eu-west-1_61EfA9adG&clientid=7el0vf5dlp1uupjr70qmes4hqc&app=dhub-field`
    );

  const payload = jwt.decode(tokenLocalStorage!);

  if (!payload) {
    window.location.replace(
      `${config.LOGIN_REDIRECT}?nextUrl=${window.origin}&poolid=eu-west-1_61EfA9adG&clientid=7el0vf5dlp1uupjr70qmes4hqc&app=dhub-field`
    );
  } else {
    if (payload["exp"] < Math.trunc(new Date().getTime() / 1000))
      window.location.replace(
        `${config.LOGIN_REDIRECT}?nextUrl=${window.origin}&poolid=eu-west-1_61EfA9adG&clientid=7el0vf5dlp1uupjr70qmes4hqc&app=dhub-field`
      );

    dispatch(
      actions.login({
        email: payload["email"],
        name: `${payload["custom:fullName"]} ${payload["family_name"]}`,
      })
    );

    const expiration: number = payload["exp"] * 1000;
    const expirationDate: Date = new Date(expiration);
    if (expiration > new Date().getTime()) {
      console.log("Window will reload at ", expirationDate, expiration, new Date().getTime());
      setTimeout(() => {
        window.location.reload();
      }, expiration - new Date().getTime());
    }
  }

  return <div>{props.children}</div>;
};

export default Auth;
