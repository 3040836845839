import { SearchIcon } from "@chakra-ui/icons";
import { InputGroup } from "@chakra-ui/input";
import {
  IconButton,
  Input,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
import React from "react";
import { FiX } from "react-icons/fi";

interface IProps {
  activeFilters: any;
  setActiveFilters: (newFilters: any) => void;
}

const SearchBox: React.FC<IProps> = (props) => {
  const { activeFilters, setActiveFilters } = props;

  const [textValue, setTextValue] = React.useState("");

  const onInputChange = (criteria: string) => {
    setActiveFilters({
      ...activeFilters,
      searchBox: criteria,
    });
    setTextValue(criteria);
  };

  return (
    <InputGroup>
      <InputLeftElement
        pointerEvents="none"
        children={<SearchIcon color="black.800" />}
      />
      <Input
        type="text"
        placeholder=""
        value={textValue}
        onChange={(event) => onInputChange(event.currentTarget.value)}
      />

      {activeFilters.searchBox.length > 0 && (
        <InputRightElement>
          <IconButton
            icon={<FiX color="black.800" pointerEvents="none" />}
            aria-label="Clear textbox"
            onClick={(_) => onInputChange("")}
            minW="fit-content"
            variant="noBackground"
          />
        </InputRightElement>
      )}
    </InputGroup>
  );
};

export default SearchBox;
