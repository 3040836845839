import { Box } from "@chakra-ui/layout";
import React from "react";

interface IProps {}

const Frame: React.FC<IProps> = (props) => {
  return (
    <Box padding="2rem 3rem" h="calc(100vh - 3rem)" overflowX="auto">
      {props.children}
    </Box>
  );
};

export default Frame;
