import localConfig from "./local";
import production from "./production";

export interface IConfig {
  API: string;
  S3_IMAGES: string;
  TOKEN_STORAGE: string;
  REFRESH_TOKEN_STORAGE: string;
  LOGIN_REDIRECT: string;
  CURRENT_ORIGIN: string;
  APP_NAME: string;
}

let config: IConfig;

const hostname = window.location.hostname;

if (hostname === "localhost") config = localConfig;
else config = production;

export default config;
