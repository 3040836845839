import React from "react";
import { Flex } from "@chakra-ui/react";

import { SubmittalStatusType } from "../redux/interfaces";

interface IProps {
  status: SubmittalStatusType;
}

const SubmittalStatusIndicator: React.FC<IProps> = (props) => {
  const { status } = props;

  const setIconBackgroundByStatus = (status: SubmittalStatusType) => {
    if (status === "A") return "green.dark";
    else if (status === "B") return "yellow.dark";
    else if (status === "C") return "primary.main";
    return "transparent";
  };

  return (
    <Flex
      p="0.1rem"
      borderRadius="50%"
      border="1px solid"
      borderColor="black.900"
      alignItems="center"
      justifyContent="center"
      w="2rem"
      h="2rem"
    >
      {!!status && (
        <Flex
          w="100%"
          h="100%"
          borderRadius="50%"
          border="1px solid"
          alignItems="center"
          justifyContent="center"
          background={setIconBackgroundByStatus(status)}
          color="white.500"
        >
          {status}
        </Flex>
      )}
    </Flex>
  );
};

export default SubmittalStatusIndicator;
