import { Flex } from "@chakra-ui/react";
import React from "react";

interface IProps {
  value: string;
  selectedColor: string;
  isDisabled?: boolean;
  isSelected?: boolean;
  onClick: (value: string) => void;
}

const Radio: React.FC<IProps> = (props) => {
  const { selectedColor, isDisabled, isSelected, onClick, value } = props;

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      w="1.5rem"
      h="1.5rem"
      cursor="pointer"
      borderWidth="1px"
      borderRadius="50%"
      borderColor="white.200"
      bg="white.200"
      color="white.500"
      fontSize="small"
      {...(!isDisabled && {
        _hover: {
          bg: selectedColor,
          borderColor: selectedColor,
        },
      })}
      {...(isSelected && {
        bg: selectedColor,
        borderColor: selectedColor,
      })}
      onClick={(_) => !isDisabled && onClick(value)}
    >
      {props.children}
    </Flex>
  );
};

export default Radio;
