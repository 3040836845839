import { configureStore } from "@reduxjs/toolkit";

import documentRepository from "./DocumentRepositorySlice";
import userStorage from "./UserSlice";
import disciplineStorage from "./DisciplineSlice";
import rfiStorage from "./RfiSlice";
import submittalStorage from "./SubmittalSlice";
import issuesStorage from "./IssuesSlice";
import projectInfoStorage from "./ProjectInfoSlice";
import viewsStorage from "./ViewsSlice";

const store = configureStore({
  reducer: {
    documentRepository: documentRepository,
    userStorage: userStorage,
    disciplines: disciplineStorage,
    rfiStorage,
    submittalStorage,
    projectInfoStorage,
    issuesStorage,
    viewsStorage,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {user: UserState}
export type AppDispatch = typeof store.dispatch;

export default store;
