import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React from "react";

interface IProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClick: () => void;
}

export const DeleteModal: React.FC<IProps> = (props) => {
  return (
    <>
      <Modal
        variant="customModal"
        isOpen={props.isOpen}
        onClose={() => props.setOpen(false)}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Delete request</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Click on Confirm to proceed.</ModalBody>

          <ModalFooter>
            <Button
              fontSize="small"
              colorScheme="blue"
              variant="solidPrimary"
              mr={3}
              onClick={() => props.setOpen(false)}
            >
              Close
            </Button>
            <Button fontSize="small" onClick={props.onClick}>
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
