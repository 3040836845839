import React, { useState } from "react";
import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  chakra,
  Icon,
} from "@chakra-ui/react";
import { TimeIcon, TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { useTable, useSortBy, Column } from "react-table";
import { BiDownload, BiUpload } from "react-icons/bi";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { IDocument } from "../../../redux/interfaces";
import { actions } from "../../../redux/DocumentRepositorySlice";
import DocumentVersionsModal from "./../DocumentVersionsModal";
import {
  convertDateToReadableString,
  getVersionId,
  navigateToExternalPage,
  splitPrefix,
} from "../../../config/utils";
import { getSignedUrl } from "../../../api/getSignedUrl";
// import { navigateToExternalPage } from "../../../config/utils";

interface IProps {
  setIsUploadDocumentModalOpen: (flag: boolean) => void;
}

const CustomTable: React.FC<IProps> = (props) => {
  const { setIsUploadDocumentModalOpen } = props;
  const dispatch = useAppDispatch();
  const [isDocumentVersionsModalOpen, setIsDocumentVersionsModalOpen] =
    useState(false);

  const data = useAppSelector(
    (state) => state.documentRepository.filteredDocumentsList
  );

  const columns = React.useMemo<Column<IDocument>[]>(
    () => [
      {
        id: "1",
        Header: "Subcategory",
      },
      {
        id: "2",
        Header: "Document",
        accessor: "name",
      },
      {
        id: "3",
        Header: "Version",
        accessor: "version",
      },
      {
        id: "4",
        Header: "Uploaded",
        accessor: "dateCreated",
      },
      {
        id: "5",
        Header: "Owner",
        accessor: "owner",
      },
      {
        id: "6",
        Header: "Update",
      },
      {
        id: "7",
        Header: "Download",
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  const setAsActiveDocument = (document: IDocument) =>
    dispatch(actions.setActiveDocument(document));

  const downloadDocument = async (versionKey: string) => {
    const { data } = await getSignedUrl(versionKey);
    navigateToExternalPage(data);
  };

  return (
    <>
      <Table {...getTableProps()}>
        <Thead>
          {headerGroups.map((headerGroup: any) => (
            <Tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  isNumeric={column.isNumeric}
                  fontSize="xs"
                  color="black.600"
                  border="none"
                >
                  {column.render("Header")}
                  <chakra.span pl="4">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <TriangleDownIcon
                          aria-label="sorted descending"
                          color="primary.main"
                        />
                      ) : (
                        <TriangleUpIcon
                          aria-label="sorted ascending"
                          color="primary.main"
                        />
                      )
                    ) : null}
                  </chakra.span>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()} bg="white.500" borderRadius="md">
          {rows.map((row: any) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()}>
                <Td
                  {...row.cells[0].getCellProps()}
                  isNumeric={row.cells[0].column.isNumeric}
                  fontSize="small"
                  color="black.600"
                >
                  {splitPrefix(row.original.prefix)[1]}
                </Td>

                <Td
                  {...row.cells[1].getCellProps()}
                  isNumeric={row.cells[1].column.isNumeric}
                  fontSize="base"
                  color="black.100"
                  fontFamily="Times New Roman"
                  letterSpacing="0.20px"
                >
                  {row.cells[1].render("Cell")}
                </Td>

                <Td
                  {...row.cells[2].getCellProps()}
                  isNumeric={row.cells[2].column.isNumeric}
                  fontSize="small"
                  color="black.600"
                >
                  {row.cells[2].value}
                  <TimeIcon
                    ml="1rem"
                    onClick={(_) => {
                      setAsActiveDocument(row.original);
                      setIsDocumentVersionsModalOpen(true);
                    }}
                    _hover={{ cursor: "pointer" }}
                  />
                </Td>

                <Td
                  {...row.cells[3].getCellProps()}
                  isNumeric={row.cells[3].column.isNumeric}
                  fontSize="small"
                  color="black.600"
                >
                  {convertDateToReadableString(row.cells[3].value, "full")}
                </Td>

                <Td
                  {...row.cells[4].getCellProps()}
                  isNumeric={row.cells[4].column.isNumeric}
                  fontSize="small"
                  color="black.600"
                >
                  {row.cells[4].render("Cell")}
                </Td>

                <Td
                  {...row.cells[5].getCellProps()}
                  // isNumeric={row.cells[5].column.isNumeric}
                  fontSize="small"
                  color="black.600"
                >
                  <Icon
                    as={BiUpload}
                    onClick={(_) => {
                      setAsActiveDocument(row.original);
                      setIsUploadDocumentModalOpen(true);
                    }}
                    fontSize="1.15rem"
                    _hover={{ color: "primary.main", cursor: "pointer" }}
                  />
                </Td>

                <Td
                  {...row.cells[6].getCellProps()}
                  // isNumeric={row.cells[5].column.isNumeric}
                  fontSize="small"
                  color="black.600"
                >
                  <Icon
                    as={BiDownload}
                    onClick={(_) => {
                      downloadDocument(getVersionId(row.original.key));
                    }}
                    fontSize="1.15rem"
                    _hover={{ color: "primary.main", cursor: "pointer" }}
                  />
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <DocumentVersionsModal
        isModalOpen={isDocumentVersionsModalOpen}
        setIsModalOpen={setIsDocumentVersionsModalOpen}
      />
    </>
  );
};

export default CustomTable;
