import React from "react";
import { Text } from "@chakra-ui/layout";

interface IProps {
  title: string;
}

const PageTitle: React.FC<IProps> = (props) => {
  return (
    <Text fontSize="base" fontFamily="Times New Roman" color="primary.main">
      {props.title}
    </Text>
  );
};

export default PageTitle;
