import React from "react";

import Bar from "react-chartjs-2";

interface IData {
  labels: string[];
  datasets: Object[];
}

interface IProps {
  data: IData;
}

const BarChart: React.FC<IProps> = (props) => {
  const myChartRef = React.createRef();
  const options = {
    cornerRadius: 4,
    indexAxis: "y",
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        grid: {
          display: false,
        },
      },
      x: {
        ticks: {
          color: "#B5B5B8",
        },
        grid: {
          drawBorder: false,
        },
      },
    },
  };

  return (
    <Bar
      ref={myChartRef}
      data={props.data}
      options={options}
      type="bar"
      height={100}
    />
  );
};

export default BarChart;
