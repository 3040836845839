import React from "react";
import { Flex, Image, Spinner } from "@chakra-ui/react";

interface IProps {
  url: string | undefined;
}

const IssueImage: React.FC<IProps> = (props) => {
  return (
    <>
      {!props.url && (
        <Flex
          justifyContent="center"
          alignItems="center"
          border="solid 1px"
          borderColor="black.800"
          width="7rem"
          height="7rem"
        >
          <Spinner color="black.800" />
        </Flex>
      )}
      {props.url && (
        <Image
          transition="ease-in-out 0.2s"
          _hover={{
            cursor: "pointer",
            transform: "scale(1.05)",
          }}
          onClick={() => {
            if (props.url) {
              window.open("", "_blank")!.document.write(`
                        <html>
                        <head><title>Issue picture</title></head>
                          <body>
                            <div style="height:100%; width:100%">
                              <img src=${props.url} alt="Issue picture" height="100%" width="100%" style="object-fit: contain;">
                            </div>
                          </body>
                        </html>
                      `);
            }
          }}
          width="7rem"
          height="7rem"
          src={props.url}
          fallbackSrc="/placeholder.png"
          objectFit="cover"
        />
      )}
    </>
  );
};

export default IssueImage;
