import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
  baseStyle: {
    padding: "0.5rem 1rem",
    fontWeight: "400",
    _hover: {
      filter: "brightness(115%)",
    },
  },
  sizes: {
    md: {
      w: "fit-content",
      h: "fit-content",
      minWidth: "7rem",
      borderRadius: "2xl",
    },
  },
  variants: {
    solidPrimary: {
      backgroundColor: "primary.main",
      color: "white.500",
      fontSize: "small",
      fontFamily: "Arial",
      _disabled: {
        backgroundColor: "black.900",
      },
      _hover: { _disabled: { backgroundColor: "black.600" } },
    },
    outlinePrimary: {
      backgroundColor: "transparent",
      color: "primary.main",
      fontSize: "small",
      fontFamily: "Arial",
      border: "1px solid",
      borderColor: "primary.main",
      _hover: {
        backgroundColor: "primary.main",
        color: "white.500",
      },
    },
    noBackground: {
      backgroundColor: "transparent",
      color: "black.400",
      fontSize: "small",
      fontFamily: "Arial",
    },
  },
  defaultProps: {
    size: "md",
  },
};

export default componentOverride;
