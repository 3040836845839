import { ComponentStyleConfig } from "@chakra-ui/react";

const componentOverride: ComponentStyleConfig = {
  baseStyle: {},
  variants: {
    customModal: {
      header: {
        color: "primary.main",
        fontSize: "small",
      },
      closeButton: {
        color: "black.800",
      },
    },
  },
};

export default componentOverride;
