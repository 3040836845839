import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ISlice {
  name: string;
  reference: string;
  photo_reference: string;
  location: string;
  created_at: string;
  kobo_link: string;
  projectsList: any[];
}

const sliceInitialState: ISlice = {
  name: "",
  reference: "",
  photo_reference: "",
  created_at: "",
  location: "",
  kobo_link: "",
  projectsList: [],
};

interface IPayloadInsert {
  name?: string;
  reference?: string;
  photo_reference?: string;
  location?: string;
  created_at?: string;
  projectsList?: any[];
}

const customSlice = createSlice({
  name: "projectInfo",
  initialState: sliceInitialState,
  reducers: {
    insertProjectInfo: (state, action: PayloadAction<IPayloadInsert>) => ({
      ...state,
      ...action.payload,
    }),
    resetProjectInfo: (state) => ({ ...state, ...sliceInitialState }),
  },
});
export const actions = customSlice.actions;
export default customSlice.reducer;
