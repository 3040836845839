import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

interface IProps {
  percentage: number;
  label: string;
  color?: string;
}

const index: React.FC<IProps> = (props) => {
  const { percentage, color, label } = props;
  return (
    <Flex alignItems="center" w="100%" h="1rem" overflow="hidden">
      <Box
        w={`${percentage}%`}
        h="100%"
        bg={color || "black.800"}
      />
      <Text
        fontSize="xs"
        fontWeight="bold"
        ml="0.5rem"
        color={color?.includes("white") || !color ? "black.800" : color}
      >
        {label}
      </Text>
    </Flex>
  );
};

export default index;
