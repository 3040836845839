import { Box, Button, Divider, Flex, HStack, Spinner } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";

import PageTitle from "../PageTitle";
import CustomTable from "./Table";
import AddNewRequest from "./Modals/AddNewRequest";
import ConversationModal from "../../shared/ConversationModal";
import MessageView from "../../shared/ConversationModal/MessageViews/MessageView";
import { useParams } from "react-router";

import { actions as disciplineActions } from "../../redux/DisciplineSlice";
import { actions as rfiActions } from "../../redux/RfiSlice";
import { deleteRfi, postNewRfi, sendNewMessage } from "../../api/rfi";
import {
  IAttachedFile,
  IRFI,
  IDept,
  IRfiResponse,
} from "../../redux/interfaces";
import { getAllDisciplines } from "./../../api/disciplines";

import { getAllRfi } from "./../../api/rfi";
import { getAllDeliverables, uploadFiles } from "../../api/documents";
import { getDepartments } from "../../api/departments";
import Filters from "./Filters";
import WriteMessage from "../../shared/ConversationModal/WriteMessage";
import RfiStatusIndicator from "../../shared/RfiStatusIndicator";

import { useFetchStatus } from "../../hooks";
import { DeleteModal } from "./Modals/DeleteModal";

interface IProps {}

const Index: React.FC<IProps> = (props) => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useFetchStatus(true);
  const rfiStorage = useAppSelector((state) => state.rfiStorage);
  const userStorage = useAppSelector((state) => state.userStorage);
  let { rfiId }: { rfiId } = useParams();

  const [isAddNewRequestOpen, setIsAddNewRequest] = React.useState(false);
  const [addClarification, setAddClarification] = React.useState(false);
  const [documents, setDocuments] = React.useState<IAttachedFile[]>([]);
  const [departmentList, setdepartmentList] = React.useState<IDept[]>([]);
  const [isOpen, setOpen] = React.useState(false);

  const setRfiMessageType = (index: number, closed?: boolean) => {
    if (index === 0) return "Request";
    if (index === 1) return "Answer";
    return index % 2 === 0 ? "Clarification" : "Clarification Answer";
  };

  const getData = async () => {
    try {
      setLoading(true);
      const rfiResponse = await getAllRfi();
      const disciplinesResponse = await getAllDisciplines();

      dispatch(rfiActions.insertRfiList(rfiResponse.data));
      dispatch(
        disciplineActions.insertDisciplines(
          disciplinesResponse.data.disciplines
        )
      );
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const onClose = () => {
    dispatch(rfiActions.resetSelectedRfi());
  };

  const acceptAndClose = async () => {
    const { data } = await sendNewMessage({
      id: rfiStorage.selectedRfi!.id!,
      message: {
        posted_by: userStorage.currentUser.email,
        files: [],
        message: "",
      },
      closed: true,
    });
    const rfiUpdated = data;
    const newList = [...rfiStorage.rfiList];
    const index = rfiStorage.rfiList.findIndex((x) => x.id === rfiUpdated.id);
    newList.splice(index, 1, rfiUpdated);
    dispatch(rfiActions.insertRfiList(newList));
    dispatch(rfiActions.insertSelectedRfi(rfiUpdated));
  };

  const createRfi = async (values: any, files: IAttachedFile[]) => {
    const { discipline, subject, question, sendTo, critical } = values;
    const toUpload = files.filter((af) => af.file);
    let uploadedfiles = files.filter((af) => !af.file);
    uploadedfiles = [...uploadedfiles, ...(await uploadFiles(toUpload))];

    const newRfi: IRFI = {
      discipline: discipline,
      departments: sendTo.map((x) => x.name),
      recipients: departmentList
        .filter((x) => sendTo.map((x) => x.name).includes(x.name))
        .map((x) => x.id),
      subject,
      critical,
      closed: false,
      conversation: [
        {
          message: question,
          posted_by: userStorage.currentUser.email,
          files: [
            ...uploadedfiles.map((aF) => ({
              id: aF.id!,
              name: aF.name,
              type: aF.type,
            })),
          ],
        },
      ],
    };

    const response = await postNewRfi(newRfi);
    dispatch(rfiActions.insertNewRfi(response.data));
    dispatch(rfiActions.insertSelectedRfi(response.data));
  };

  const sendNewResponse = async (files: IAttachedFile[], message: any) => {
    const toUpload = files.filter((af) => af.file);
    let uploadedFiles = files.filter((af) => !af.file);
    uploadedFiles = [...uploadedFiles, ...(await uploadFiles(toUpload))];
    const newMessage: IRfiResponse = {
      id: rfiStorage.selectedRfi!.id!,
      message: {
        message: message.messageText,
        posted_by: userStorage.currentUser.email,
        files: [
          ...uploadedFiles.map((aF) => ({
            id: aF.id!,
            name: aF.name,
            ...(aF.type && { type: aF.type }),
          })),
        ],
      },
    };
    const { data } = await sendNewMessage(newMessage);
    const rfiUpdated = data;
    const newList = [...rfiStorage.rfiList];
    const index = rfiStorage.rfiList.findIndex((x) => x.id === rfiUpdated.id);
    newList.splice(index, 1, rfiUpdated);
    dispatch(rfiActions.insertRfiList(newList));
    dispatch(rfiActions.insertSelectedRfi(rfiUpdated));
  };

  const show = () => {
    const type = setRfiMessageType(rfiStorage.selectedRfi!.conversation.length);
    const closed = rfiStorage.selectedRfi!.closed;
    if (closed) return false;
    if (type === "Answer") return true;
    else if (type === "Request" && addClarification) return true;
    return false;
  };
  useEffect(() => {
    getData();
    getDepartments().then((res) =>
      setdepartmentList(
        res.data.map((receiver) => ({
          ...receiver,
          label: receiver.name,
          value: receiver.id,
        }))
      )
    );
    getAllDeliverables().then(({ data }) => {
      setDocuments(
        data.map<IAttachedFile>((doc) => ({
          id: doc.versions.pop()!.key!.split("/")[1],
          name: doc.name,
          type: "selection",
        }))
      );
      if (rfiId && rfiStorage.rfiList.length > 0) {
        const find = rfiStorage.rfiList.find((rfi) => rfi.id === +rfiId);
        if (find) dispatch(rfiActions.insertSelectedRfi(find));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onClickDeleteRFI = async () => {
    if (rfiStorage.selectedRfi?.id) {
      await deleteRfi(rfiStorage.selectedRfi.id);
      window.location.reload();
    }
  };

  // We are sure the id that comes from the API is not undefined.
  const conversationSorted = rfiStorage.selectedRfi
    ? [...rfiStorage.selectedRfi.conversation].sort((a, b) => a.id! - b.id!)
    : [];

  const canDeleteRFI =
    conversationSorted.length >= 1
      ? conversationSorted[0].posted_by === userStorage.currentUser.email
      : false;

  React.useEffect(() => {
    setAddClarification(false);
  }, [rfiStorage.selectedRfi]);

  return (
    <Flex flexDirection="column" h="100%">
      <Flex justifyContent="space-between" alignItems="top" mb="1.25rem">
        <PageTitle title="Request for information" />
        <Box>
          {/* <IconButton
            variant="noBackground"
            icon={<BiDownload />}
            aria-label="Download all RFI"
            fontSize="2xl"
            color="primary.main"
          /> */}
          <Button
            variant="solidPrimary"
            onClick={() => {
              setIsAddNewRequest(true);
            }}
          >
            Add request
          </Button>
        </Box>
      </Flex>

      <Flex alignItems="top" flex="1" maxH="95%">
        <Filters />
        <Box flex="1.25">
          {loading && (
            <Spinner
              color="primary.main"
              position="absolute"
              left="50%"
              top="50%"
              transform="translate(-50%)"
            />
          )}
          {!loading && <CustomTable />}
        </Box>
        {!!rfiStorage.selectedRfi && (
          <Box flex="1" ml="1.5rem" mt="1.5rem">
            <ConversationModal
              selectedConversation={rfiStorage.selectedRfi}
              onClose={onClose}
              indicator={
                <RfiStatusIndicator
                  rfiStatus={rfiStorage.selectedRfi.status!}
                />
              }
            >
              {rfiStorage.selectedRfi.conversation.map((message, index) => (
                <MessageView
                  key={`message_${index}`}
                  message={message}
                  type={setRfiMessageType(index)}
                  conversation={rfiStorage.selectedRfi}
                  index={index}
                />
              ))}
              {show() && (
                <WriteMessage
                  submitNewMessage={sendNewResponse}
                  type={setRfiMessageType(
                    rfiStorage.selectedRfi.conversation.length
                  )}
                  allowedAttachments={[
                    {
                      label: "Upload new file",
                      type: "newUpload",
                    },
                    {
                      label: "Link from storage",
                      type: "selection",
                      options: documents,
                    },
                  ]}
                />
              )}
              {setRfiMessageType(
                rfiStorage.selectedRfi!.conversation.length
              ) === "Request" &&
                !addClarification && (
                  <>
                    <HStack w="100%" spacing="1.5rem" justifyContent="center">
                      <Button
                        variant="outlinePrimary"
                        onClick={(_) => setAddClarification(true)}
                      >
                        Add clarification
                      </Button>
                      <Button variant="solidPrimary" onClick={acceptAndClose}>
                        Accept answer
                      </Button>
                    </HStack>
                  </>
                )}

              <Divider />
              {canDeleteRFI && (
                <>
                  <Flex flexDirection="row-reverse" width="100%">
                    <Button fontSize="small" onClick={() => setOpen(true)}>
                      Delete request
                    </Button>
                  </Flex>
                  <Divider />
                </>
              )}
            </ConversationModal>
          </Box>
        )}
      </Flex>
      <AddNewRequest
        title="New request"
        isAddNewRequestOpen={isAddNewRequestOpen}
        setIsAddNewRequest={setIsAddNewRequest}
        submit={createRfi}
        sendToList={departmentList}
        isAttachmentsAllowed
        allowedAttachments={[
          {
            label: "Link from storage",
            type: "selection",
            options: documents,
          },
          {
            label: "Upload new file",
            type: "newUpload",
          },
        ]}
      />
      <DeleteModal
        isOpen={isOpen}
        setOpen={setOpen}
        onClick={onClickDeleteRFI}
      />
    </Flex>
  );
};

export default Index;
