import React, { useState } from "react";
import { Box, Image, Text } from "@chakra-ui/react";
import { useHistory } from "react-router";
import { motion } from "framer-motion";
// import { useAppDispatch } from "../../redux/hooks";

interface IProps {
  location: string;
  name: string;
  reference: string;
  photo_reference: string;
  activateProject: () => void;
}

const ProjectCard: React.FC<IProps> = (props) => {
  const { location, name, reference, photo_reference, activateProject } = props;
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const imageIsLoaded = () => {
    setLoaded(true);
  };
  return (
    <Box
      shadow="md"
      bg="white.500"
      p="1rem"
      minHeight="3rem"
      borderRadius="md"
      transition="all 0.3s"
      _hover={{
        shadow: "lg",
        cursor: "pointer",
        transform: "scale(1.1)",
      }}
      onClick={(_) => {
        activateProject();
        history.push(`/${reference}`);
      }}
    >
      <Text color="primary.main" fontSize="xs" fontWeight="bold">
        {location}
      </Text>
      <Text fontSize="base" m="0.25rem 0" fontFamily="Times New Roman">
        {name}
      </Text>
      <motion.div animate={loaded ? { opacity: ["0", "1"] } : {}}>
        <Image
          src={photo_reference}
          alt="Project render"
          fallbackSrc="/placeholder.png"
          objectFit="cover"
          w="100%"
          h="15rem"
          onLoad={imageIsLoaded}
        />
      </motion.div>
    </Box>
  );
};

export default ProjectCard;
