import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IIssue } from "./interfaces";

interface ISlice {
  issueList: IIssue[];
  selectedIssueList: IIssue[];
}

const sliceInitialState: ISlice = {
  issueList: [],
  selectedIssueList: [],
};

const customSlice = createSlice({
  name: "issues",
  initialState: sliceInitialState,
  reducers: {
    insertList: (state, action: PayloadAction<IIssue[]>) => ({
      ...state,
      issueList: applyModifiers(action.payload),
    }),
    insertNewIssue: (state, action: PayloadAction<IIssue>) => ({
      ...state,
      issueList: applyModifiers([action.payload, ...state.issueList]),
    }),
    resetIssueList: (state) => ({ ...state, issueList: [] }),
    insertSelectedList: (state, action: PayloadAction<IIssue[]>) => ({
      ...state,
      selectedIssueList: applyModifiers(action.payload),
    }),
  },
});

const applyModifiers = (issueList: IIssue[]) =>
  issueList.sort(orderByCreationDate);

const orderByCreationDate = (a: IIssue, b: IIssue) => {
  if (!a.date || !b.date || a.date === b.date) return 0;

  return a.date < b.date ? 1 : -1;
};

export const actions = customSlice.actions;
export default customSlice.reducer;
