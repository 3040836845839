import React from "react";

import { Line } from "react-chartjs-2";

interface IProps {
  issueData: {
    date: Date;
    label: string;
    open: number;
    solved: number;
  }[];
}

const LineChart: React.FC<IProps> = (props) => {
  const { issueData } = props;
  const data = {
    labels: issueData.map((it) => it.label),
    datasets: [
      {
        label: "Total opened",
        data: issueData.map((it) => it.open),
        fill: "+1",
        backgroundColor: "rgba(230,30,40,0.15)",
        borderColor: "#E61E28",
        borderWidth: 2,
        tension: 0.1,
        pointRadius: 1,
      },
      {
        label: "Solved",
        data: issueData.map((it) => it.solved),
        fill: true,
        backgroundColor: "rgba(70,174,79,0.2)",
        borderColor: "#54AE4F",
        borderWidth: 2,
        tension: 0.1,
        pointRadius: 1,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        labels: {
          font: {
            size: 9,
          },
        },
        position: "bottom",
        align: "start",
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
    },
  };
  return <Line type="line" data={data} options={options} height={140} />;
};

export default LineChart;
