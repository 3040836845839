import React from "react";
import { Grid, GridItem } from "@chakra-ui/react";

interface IProps {
  reducedHeaders: boolean;
}

const itemsComplete = [
  { label: "Document", colSpan: 3 },
  { label: "Disciplines", colSpan: 1 },
  { label: "Subdisciplines", colSpan: 1 },
  { label: "Floor", colSpan: 1 },
  { label: "Area", colSpan: 1 },
  { label: "Subarea", colSpan: 1 },
  { label: "Priority", colSpan: 1 },
  { label: "Status", colSpan: 1 },
];

const itemsReduced = [
  { label: "Document", colSpan: 3 },
  { label: "Disciplines", colSpan: 2 },
  { label: "Floor", colSpan: 1 },
  { label: "Area", colSpan: 2 },
  { label: "Priority", colSpan: 1 },
  { label: "Status", colSpan: 1 },
];

const Index: React.FC<IProps> = (props) => {
  const items = props.reducedHeaders ? itemsReduced : itemsComplete;

  return (
    <Grid
      fontSize="small"
      color="black.600"
      w="100%"
      templateColumns="repeat(11, 1fr)"
      gap={6}
      px="2rem"
      mb={3}
    >
      {items &&
        items.map((it, index) => (
          <GridItem key={index} colSpan={it.colSpan} textAlign="left">
            {it.label}
          </GridItem>
        ))}
    </Grid>
  );
};

export default Index;
