import { Box, Text } from "@chakra-ui/layout";
import React from "react";

import { ICategory } from "../../../redux/interfaces";
import CategoryCard from "./CategoryCard";

interface IProps {
  categoriesList: ICategory[];
  activeFilters: any;
  setActiveFilters: (newFilters: any) => void;
}

const CategoryList: React.FC<IProps> = (props) => {
  const { categoriesList, activeFilters, setActiveFilters } = props;
  const handleClick = (categoryName: string) =>
    setActiveFilters({ ...activeFilters, category: categoryName });

  return (
    <Box mt="1.75rem">
      <Text color="black.600" fontSize="xs" mb="0.75rem">
        Categories
      </Text>
      {categoriesList.map((category, index) => (
        <CategoryCard
          key={`categoryList_${category}_${index}`}
          categoryName={category.label}
          isSelected={activeFilters.category === category.label}
          handleClick={handleClick}
        />
      ))}
    </Box>
  );
};

export default CategoryList;
