import axios from "axios";

export const refreshToken = (refreshToken: string) =>
  axios.post(
    `https://login-microservice.auth.eu-west-1.amazoncognito.com/oauth2/token/`,
    null,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      params: {
        grant_type: "refresh_token",
        refresh_token: refreshToken,
        client_id: "7kgivu38oln5mbbn77suhqdss",
      },
    }
  );
