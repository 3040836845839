import { IConfig } from ".";

const config: IConfig = {
  API: "http://localhost:5000/field",
  S3_IMAGES: "",
  TOKEN_STORAGE: "arup_login_microservice_user",
  REFRESH_TOKEN_STORAGE: "arup_login_microservice_user_refresh",
  LOGIN_REDIRECT: "https://auth.dhub.arup.com/",
  CURRENT_ORIGIN: window.location.origin,
  APP_NAME: "dHub Field",
};

export default config;
