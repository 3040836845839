import React from "react";
import { Route, Router, Switch, useHistory } from "react-router";
import { createBrowserHistory } from "history";
import { ChakraProvider } from "@chakra-ui/react";
import { css, Global } from "@emotion/react";
import "focus-visible/dist/focus-visible";

import theme from "./config/theme";
import "./reset.css";

import Navbar from "./core/Navbar";
import Frame from "./core/Frame";
import Auth from "./core/Auth";
import Axios from "./core/Axios";
import Documents from "./components/DocumentsView/Documents";
import RFI from "./components/RFIView";
import Home from "./components/HomeView";
import Submittals from "./components/SubmittalsView";
import Issues from "./components/IssuesView";
import LandingMultiproject from "./components/LandingMultipojectView";
import { getAllProjects } from "./api/projects";
import { actions as projectInfoActions } from "./redux/ProjectInfoSlice";
import { useAppDispatch, useAppSelector } from "./redux/hooks";
import { useFetchStatus, useTabTitle } from "./hooks";
import Loading from "./shared/LoadingView";
import Error from "./shared/ErrorView";

const GlobalStyles = css`
  /*
    This will hide the focus indicator if the element receives focus    via the mouse,
    but it will still show up on keyboard focus.
  */
  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
  }
`;

export const browserHistory = createBrowserHistory();

const ContentFetching = ({ children }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const projectsList = useAppSelector(
    (state) => state.projectInfoStorage.projectsList
  );
  const { currentTitle, changeTitle } = useTabTitle();
  const [loading, setLoading, error, setError] = useFetchStatus(true);

  const getData = async () => {
    try {
      setLoading(true);
    } catch (err) {
      console.log(err);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  const getProjectsAndCheck = async () => {
    // Check whether yet exists projects lists or have to fetch it
    let fetchedProjectsList;
    if (projectsList.length) fetchedProjectsList = projectsList;
    else {
      const { data } = await getAllProjects();
      fetchedProjectsList = data;
    }

    // Check if project url is valid
    const { location } = history;
    const projectReference = location.pathname
      .split("/")
      .filter((x) => x.trim() !== "")
      .shift();
    if (projectReference && fetchedProjectsList.length) {
      const correspondingProject = fetchedProjectsList.find(
        (x) => x.reference === projectReference
      );
      if (!correspondingProject) {
        history.push("/");
        return setLoading(false);
      }
      dispatch(
        projectInfoActions.insertProjectInfo({
          ...correspondingProject,
          projectsList: fetchedProjectsList,
        })
      );
      changeTitle(`${currentTitle} | ${correspondingProject.name}`);
      return getData();
    } else {
      history.push("/");
      return setLoading(false);
    }
  };

  React.useEffect(() => {
    getProjectsAndCheck();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) return <Loading />;
  if (error) return <Error errorMessage={error.message} />;

  return children;
};

export const App: React.FC = () => {
  return (
    <ChakraProvider theme={theme}>
      <Global styles={GlobalStyles} />
      <Router history={browserHistory}>
        <Auth>
          <Axios />
          <ContentFetching>
            <Switch>
              <Route
                path="/:projectName/issues"
                component={() => (
                  <>
                    <Navbar />
                    <Frame>
                      <Issues />
                    </Frame>
                  </>
                )}
              />
              <Route
                path="/:projectName/submittals"
                component={() => (
                  <>
                    <Navbar />
                    <Frame>
                      <Submittals />
                    </Frame>
                  </>
                )}
              />
              <Route
                path="/:projectName/rfi/:rfiId"
                component={() => (
                  <>
                    <Navbar />
                    <Frame>
                      <RFI />
                    </Frame>
                  </>
                )}
              />
              <Route
                path="/:projectName/rfi"
                component={() => (
                  <>
                    <Navbar />
                    <Frame>
                      <RFI />
                    </Frame>
                  </>
                )}
              />
              <Route
                path="/:projectName/documents"
                component={() => (
                  <>
                    <Navbar />
                    <Frame>
                      <Documents />
                    </Frame>
                  </>
                )}
              />
              <Route
                path="/:projectName"
                component={() => (
                  <>
                    <Navbar />
                    <Frame>
                      <Home />
                    </Frame>
                  </>
                )}
              />
              <Route
                path="/"
                component={() => (
                  <>
                    <Navbar />
                    <Frame>
                      <LandingMultiproject />
                    </Frame>
                  </>
                )}
              />
            </Switch>
          </ContentFetching>
        </Auth>
      </Router>
    </ChakraProvider>
  );
};
